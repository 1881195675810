@import '../../../styles/tokens.less';

.disconnected-grid-container {
  display: grid;

  @media @desktop {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-gap: @spacing-xxxl;

    > div:nth-child(1) {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      white-space: pre-line;
    }

    > div:nth-child(2) {
      grid-column: 2 / 3;
      grid-row: 1 / 3;
      display: grid;
      height: 75%;
      grid-gap: @spacing-md;
    }

    > div:nth-child(3) {
      grid-column: 1 / 2;
      grid-row: 2 / 4;
    }

    > div:nth-child(4) {
      grid-column: 2 / 3;
      grid-row: 3 / 4;
    }

    .video-title {
      text-align: center;
    }
  }

  @media @tablet {
    grid-template-columns: 1fr;
    margin-top: @spacing-xxl;

    > div:nth-child(1) {
      margin-bottom: @spacing-xxl;
    }

    > div:nth-child(2) {
      margin: auto;
      width: 80%;
    }

    > div:nth-child(3) {
      margin-bottom: @spacing-xxl;
    }

    .video-title {
      text-align: center;
      margin: @spacing-md 0 @spacing-xxl 0;
    }
  }

  @media @mobile {
    grid-template-columns: 1fr;

    > div:nth-child(1) {
      margin-bottom: @spacing-xxl;
    }

    > div:nth-child(2) {
      width: 100%;
      margin-bottom: @spacing-xxl;
    }

    > div:nth-child(3) {
      margin: @spacing-xxl 0 @spacing-lg 0;
    }

    .video-title {
      text-align: center;
      position: relative;
    }
  }
}

.ant-row&.page-header-container {
  @media @desktop {
    margin-bottom: @spacing-xxxl;
  }

  @media @mobile {
    margin-bottom: @spacing-xl;
  }
}

.ant-row&.content-card {
  @media @desktop {
    margin-bottom: @spacing-xxxl;
  }

  @media @tablet {
    margin: @spacing-xxl 0 @spacing-xxxl 0;
  }

  @media @mobile {
    margin: @spacing-xl 0 @spacing-xxl 0;
  }
}

.gong__ul__li {
  font-size: @font-size-md;
  margin-bottom: @spacing-xs;
}

@primary-color: #004053;