@import '../../../../../styles/tokens.less';

.workspaces-linked-accounts {
  &__no-data {
    text-align: center;
    font-weight: @font-weight-semibold;
    margin-top: @size-300;
  }
}

@primary-color: #004053;