@import '../../../../../styles/tokens.less';

.workspaces-body {
  &__container {
    padding-top: @size-500;
  }

  &__loader .loading-container {
    padding-top: @spacing-xxl;
    margin: auto;
  }
}

@primary-color: #004053;