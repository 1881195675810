@import '../../styles/tokens.less';

.switch {
  display: inline-flex;
  position: relative;
  transition-property: background-color, border-color, color, fill, stroke;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 9999px;
  border-width: 2px;
  border-color: transparent;
  cursor: pointer;
  height: @size-200;
  width: @size-500;
  padding: 0;
}
.switch[aria-checked='false'] {
  background-color: @color-fog-300;
}

.switch[aria-checked='true'] {
  background-color: @color-ocean-500;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.knob {
  display: inline-block;
  background-color: #ffffff;
  transition-property: background-color, border-color, color, fill, stroke, opacity, transform;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 9999px;
  pointer-events: none;
  height: @size-150;
  width: @size-150;
}

.switch[aria-checked='true'] > .knob {
  transform: translateX(200%);
}

@primary-color: #004053;