@import '../../../../../styles/tokens.less';

.signature {
  border-radius: @radius-xs;
  display: flex;
  align-items: center;
  padding: @spacing-xxs;
  height: 100%;

  &.primary {
    background-color: @color-lake-200;
    border: 2px solid @color-lake-200;
  }

  &.secondary {
    background-color: @color-urchin-200;
    border: 2px solid @color-urchin-200;
  }

  .signature-icon-container {
    flex-grow: 1;

    svg {
      height: @size-500;
      width: @size-500;
      margin: 0 auto;
      display: block;
    }
  }

  .signature-signee-container {
    flex-grow: 2;

    .proposify-typography:first-of-type,
    div.proposify-typography.size-sm {
      margin-bottom: 0;
    }
  }

  &.signed-signature {
    .signature-icon-container {
      border: 3px solid @color-orca-999;
      border-right: 0;
      border-top-left-radius: @size-200;
      border-bottom-left-radius: @size-200;
      height: calc(@size-700 + @size-050);
      width: @size-200;
    }

    .signature-signee-container {
      padding-left: @spacing-xxs;
    }

    .proposify-typography {
      &.desktop {
        font-family: @font-water-brush;
        margin-bottom: 0;
        margin-top: 0;
      }

      &.size-xs {
        font-family: @font-sans-serif;
        margin-bottom: 0;
      }
    }
  }
}

.signature-selected {
  border: 2px solid @color-ocean-700;
  border-radius: @radius-xs;
}

.signature__tooltip {
  background: @color-deepsea-800;
  border-radius: @radius-xs @radius-xs 0px 0px;
  color: @color-orca-000;
  padding: @spacing-xxs @spacing-xs;
}

.unsigned_signature {
  z-index: @z-index-base;
  &[data-resizable='true'] {
    &:active,
    &:focus,
    &:focus-within,
    &:hover {
      .editor__page__draggable__grid-resize-handle-icon {
        visibility: visible;
        svg {
          rect{
            stroke:@color-sunset-500;
            background-color: @color-orca-000;
          }
        }
        
      }
    }
  }
}

.signature,
.unsigned_signature {
  &.react-draggable-dragging {
    z-index: @z-index-level4;
    box-shadow: @box-shadow-block;
  }
}

@primary-color: #004053;