@import '../../../../styles/tokens.less';

.v3-beta-badge {
  background-color: @color-navy-80;
  border-radius: 100px;
  font-size: @font-size-xsm;
  color: @color-navy-10;
  font-weight: @font-weight-semibold;
  padding: 0 @spacing-xs;
}

@primary-color: #004053;