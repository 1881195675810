@import '../../../../../../styles/tokens.less';

.template {
  &__container {
    display: flex;
    flex-direction: column;
    padding-top: @spacing-md;
  }

  &__heading {
    display: flex;
    width: 100%;
    color: @color-lake-600;
    font-weight: @font-weight-semibold;
    font-size: @font-size-md;
    justify-content: space-between;
  }

  &-pointer {
    cursor: pointer;
  }

  &__divider {
    margin: 0 0 @spacing-sm 0;
  }

  &__search-templates {
    width: 500px;
    border: 1px solid #555;
    padding: 9px 4px 9px 40px;
  }
}

@primary-color: #004053;