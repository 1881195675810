@color-picker-button-size: 32px;

.editor-color-picker {
  .MuiButtonBase-root {
    &.color-button {
      width: @color-picker-button-size;
      height: @color-picker-button-size;
      min-height: @color-picker-button-size;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        transform: scale(1.1);
        filter: brightness(95%);
      }
    }
  }
}

.color-picker-container {
  // The following hack is to hide the hex section in the color picker.
  div > div {
    &:nth-of-type(3) {
      > div {
        &:first-child {
          display: none;
        }
      }
    }
  }
}

@primary-color: #004053;