@import '../../../../../styles/tokens.less';

.signature_button {
  margin: 0;
  width: 100%;
  height: 100%;
  border-radius: @radius-xs;
  border-width: 1px;
  border-style: solid;
  border-color: var(--user-border-color);
  padding: @spacing-md @spacing-xs;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: @spacing-xs;
  background-color: var(--user-bg-color);
  color: var(--user-border-color);

  &[draggable='true'] {
    cursor: grab;
  }

  &:active,
  &:focus {
    outline: auto;
  }
}

.signature_button__content {
  color: @color-fog-800;
  font-weight: @font-weight-semibold;
}

@primary-color: #004053;