@import '../../../../../styles/tokens.less';

.copy-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: @color-orca-000;
  padding: @spacing-lg @spacing-md;
  border-radius: @radius-sm;
  border: 1px solid @color-fog-400;

  &__info {
    display: flex;
    align-items: flex-start;
    gap: @spacing-md;
    flex-shrink: 0;

    @media @mobile {
      margin-bottom: @spacing-md !important;
    }
  }

  &__action {
    display: flex;
    gap: @spacing-md;
    align-items: center;
    flex-wrap: wrap;
    width: 50%;
    justify-content: flex-end;

    input {
      width: 214px;
    }

    @media @mobile {
      flex-grow: 1;
    }
  }
}

@primary-color: #004053;