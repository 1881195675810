@import '../../styles/colors.less';
@import '../../styles/fonts.less';
@import '../../styles/spacing.less';
@import '../../styles/sizes.less';
@import '../../styles/radius.less';

.custom-snackbar {
  display: flex;
  width: 100%;
  padding: 6px @spacing-md;
  border-radius: @radius-xs;
  background-color: @color-deepsea-200;
  align-items: center;

  .snackbar-title {
    flex: 1;
    padding: @spacing-xs 0;
    text-align: center;

    .ant-typography {
      margin: 0px;
      color: @color-deepsea-700;
      font-weight: 500;
      line-height: 20px;
    }
  }

  .snackbar-actions {
    margin-right: -@spacing-xs;

    .snackbar-btn {
      background: transparent;
      border: none;
      color: @color-deepsea-800;
      font-weight: @font-weight-semibold;
      cursor: pointer;
      padding: 7px @spacing-sm;

      &:hover {
        background: #d0bcff14;
        border-radius: 100px;
      }
    }
  }
}

@primary-color: #004053;