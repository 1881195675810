@import '../../../../../../../styles/tokens.less';

.name {
  &__initials {
    background-color: @color-urchin-200;
    border: 1px solid @color-urchin-600;
    border-radius: 50%;
    margin-right: @spacing-md;
    width: @size-500;
    height: @size-500;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
}

@primary-color: #004053;