@import '../../../../../styles/tokens.less';

.document-create-form {
  display: flex;
  flex-direction: column;
  background-color: @color-orca-000;
  margin-top: @spacing-xxl;

  &__form-field {
    margin: @spacing-sm 0;
    display: flex;
    flex-direction: column;
  }

  &__date-picker {
    padding: @spacing-sm @spacing-sm;
    width: 100%;
  }

  &__create-button {
    align-self: end;
  }

  &__loader {
    width: 70px;
    margin: auto;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 1;
    top: 40%;
  }
}

.error {
  border: solid 1px @color-lighthouse-600;
}

@primary-color: #004053;