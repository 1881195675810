@import '../../styles/tokens.less';
@import '../../styles/typography.less';
@import '../../styles/menu.less';

button.proposify-hamburger-menu.ant-btn {
  border: @hamburger-menu-box-shadow;
  box-shadow: @hamburger-menu-border!important;
  color: @hamburger-menu-color;

  &:hover {
    color: @hamburger-menu-color--hover;
    background-color: @hamburger-menu-background-color--hover;
  }

  @media @tablet {
    margin-right: @spacing-md;
  }

  @media @mobile {
    margin-right: @spacing-md;
  }
}

div.ant-drawer-open.proposify-menu {
  .ant-drawer-content-wrapper {
    width: @drawer-menu-width;

    @media @tablet {
      width: @drawer-menu-width;
    }

    @media @mobile {
      width: @drawer-menu-width--mobile;
    }
  }

  div.ant-drawer-content {
    background-color: @drawer-menu-background-color--open;

    div.ant-drawer-body {
      padding: 0;
    }
  }

  div.ant-drawer-body > a {
    color: @drawer-menu-font-color--open;
  }

  div.ant-drawer-header {
    background-color: @drawer-menu-background-color--open;
    color: @drawer-menu-font-color--open;
    border-bottom: @drawer-menu-title-border-bottom;
    padding: @drawer-menu-header-padding;
    height: @drawer-menu-header-height;

    div.ant-drawer-header-title {
      display: @drawer-menu-title-display;
      height: inherit;

      .ant-drawer-title {
        height: inherit;

        .proposify-logo {
          height: @drawer-menu-proposify-logo-height;
          width: @drawer-menu-proposify-logo-width;
          margin: @drawer-menu-title-margin;
        }
      }

      button.ant-drawer-close {
        color: @drawer-menu-close-button-color;
        order: @drawer-menu-close-button-order;
        align-self: @drawer-menu-close-button-align-self;
        font-size: @drawer-menu-close-button-font-size;
        width: @drawer-menu-close-button-width;
        height: @drawer-menu-close-button-height;
        border: @drawer-menu-close-button-border;
        box-sizing: @drawer-menu-close-button-box-sizing;
        margin: @drawer-menu-close-button-margin;
        padding: @drawer-menu-close-button-padding;

        &:hover {
          color: @drawer-menu-color--hover;
        }
      }
    }
  }

  div.menu-item-container {
    display: flex;
    font-size: @drawer-menu-font-size--open;
    font-family: @drawer-menu-font-family;
    line-height: @drawer-menu-content-line-height;
    height: @drawer-menu-content-height;
    align-items: center;
    position: relative;
    padding-left: 8.5px;

    &:hover {
      transition: all ease-in-out 0.2s;
      background-color: @drawer-menu-content-hover-color;
      color: @drawer-menu-color--hover;
    }
  }

  div.menu-item-icon {
    margin-right: @drawer-menu-icon-margin-right;
    width: @drawer-menu-icon-wrapper-dimensions;
    height: @drawer-menu-icon-wrapper-dimensions;
    display: flex;
    align-items: center;

    svg {
      width: @drawer-menu-icon-dimensions;
      height: @drawer-menu-icon-dimensions;
      display: block;
      margin: 0 auto;
    }
  }
}

@primary-color: #004053;