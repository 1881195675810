@import '../../../../../../../styles/tokens.less';

.template-folder-card {
  &__container {
    display: flex;
    flex-direction: column;
    width: 235px;
    margin: @spacing-sm;
    box-shadow: @box-shadow-300;
    cursor: pointer;

    &:hover {
      box-shadow: @box-shadow-500;
    }

    span.lake > svg {
      height: 5em;
      width: 5em;
    }
  }

  &__body {
    height: 274px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__footer {
    text-align: center;
    border-top: 1px solid @color-fog-800;
    padding: @spacing-sm @spacing-md;
    font-weight: 500;
  }
}

@primary-color: #004053;