@import '../../styles/buttonpageaction.less';

.page-action {
  height: @page-action-height;
  a {
    text-decoration: none;
  }

  @media @desktop {
    margin-left: @page-action-margin-left--desktop;
  }

  @media @mobile {
    margin-bottom: @page-action-margin-bottom--mobile;
  }

  @media @tablet {
    margin-left: @page-action-margin-left--tablet;
  }

  &.primary {
    order: 1;
  }
}

@primary-color: #004053;