@import '../../styles/menu.less';

button.proposify-hamburger-menu.ant-btn {
  border: @hamburger-menu-box-shadow;
  box-shadow: @hamburger-menu-border;

  &:focus {
    background-color: transparent;
  }

  &:hover {
    background-color: @hamburger-menu-background-color--hover;

    .anticon-menu {
      color: @hamburger-menu-color--hover;
    }
  }

  .anticon-menu {
    color: @hamburger-menu-color;
  }
}

@primary-color: #004053;