@import '../../styles/typography.less';

.proposify-typography.default-font {
  font-family: @default-font;
  color: @color-light-primary10;
}

h1.ant-typography,
h1 {
  .heading-1();
}

h2.ant-typography,
h2 {
  .heading-2();
}

h3.ant-typography,
h3 {
  .heading-3();
}

h4.ant-typography,
h4 {
  .heading-4();
}

h5.ant-typography,
h5 {
  .heading-5();
}

div.proposify-typography {
  &.size-xs {
    .paragraph-xs();
  }

  &.size-sm {
    .paragraph-sm();
  }

  &.size-md {
    .paragraph-md();
  }

  &.size-lg {
    .paragraph-lg();
  }
}

@primary-color: #004053;