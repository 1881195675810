@import '../../../../styles/tokens.less';

.create-document-modal {
  .start-from-scratch-button {
    border-radius: 100px;
    font-style: normal;
    text-transform: none;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0.245px;
    font-family: @default-font;
    text-align: center;
    color: #00658c;
    text-align: center;
    padding: 10px 12px;

    &:hover,
    &:focus,
    &:active {
      background: rgba(0, 101, 140, 0.08);
    }
  }
}

@primary-color: #004053;