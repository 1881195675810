@import '../../../../../styles/tokens.less';

.form-field {
  display: flex;
  background-color: @color-orca-000;
  border: 1px solid @color-fog-400;

  > label {
    padding: @spacing-xs @spacing-md;
    min-width: 88px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:first-child {
    border-radius: @radius-sm @radius-sm 0 0;
  }

  &:not(.is-error) {
    &:not(:first-child) {
      border-top: none;
    }
  }

  &:last-child {
    border-radius: 0 0 @radius-sm @radius-sm;
  }

  &.is-error {
    border-color: @color-lighthouse-600;
  }

  & .proposify-input {
    border: none;
    height: 40px;

    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }

  &__control {
    flex-grow: 1;
    display: flex;
  }
}

.cc-containers {
  display: flex;
  align-items: center;
  padding-right: @spacing-xs;

  & .cc_button {
    color: @color-lake-700;
    font-weight: @font-weight-semibold;
    text-decoration: @color-lake-700 underline;
    font-size: @font-size-sm;
    padding: 0 @spacing-xs;
    background: none;
    border: none;
  }
}

.from__container {
  display: flex;
  align-items: center;
  padding: 0 @spacing-sm;
}

.from-value {
  background-color: @color-ocean-200;
  border-radius: 24px;
  padding-left: @spacing-sm;
  padding-right: @spacing-sm;
}

.email-editor-label {
  display: block;
  margin-bottom: @spacing-xs;
}

.form__footer {
  display: flex;
  justify-content: space-between;
  margin-top: @spacing-sm;
}

.actionsWrapper {
  min-width: 1px;
}

.send-email {
  &-text {
    color: @color-fog-700;
    text-align: left;
  }

  &-text::before {
    content: attr(data-text);
    position: absolute;
    width: 100%;
    color: @color-orca-000;
    overflow: hidden;
    animation: animate 3s linear infinite;
    text-align: left;
  }

  @keyframes animate {
    0% {
      width: 0;
    }

    100% {
      width: 100%;
    }
  }
}

@primary-color: #004053;