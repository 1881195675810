@import '../../styles/queries.less';

@logo-light-bg: '../../images/proposify-logo-light-bg.svg';
@logo-dark-bg: '../../images/proposify-logo-dark-bg.svg';

.proposify-logo {
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  display: inline-block;
  width: 107px;
  height: 40px;

  @media @desktop {
    display: none;
  }

  &.light-bg {
    background-image: url(@logo-light-bg);
  }

  &.dark-bg {
    background-image: url(@logo-dark-bg);
  }
}

@primary-color: #004053;