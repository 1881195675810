@import '../../../../../styles/tokens.less';

.salesforce-objects {
  &__container {
    margin-top: @spacing-xl;
  }

  &__button {
    margin-top: @spacing-lg;
  }

  &__loader {
    width: 70px;
    margin: auto;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 1;
    top: 40%;
  }
}

@primary-color: #004053;