@import '../../../styles/tokens.less';
@import '../../../styles/typography.less';
@import '../../../styles/editor.less';

.table__library {
  &__box {
    width: 100%;
    height: 50vh;
    overflow-y: scroll;
  }
  &__container {
    margin-top: 24px;
    height: 50%;
  }
}

.table__container {
  &_title {
    color: var(--app-fog-500, @color-fog-500);
    font-family: @default-font;
    font-size: @font-size-sm;
    font-style: normal;
    font-weight: @font-weight-normal;
    line-height: @line-height-sm;
  }
}

.table__content__section {
  margin-top: 10px;
}

@primary-color: #004053;