@import '../../../../styles/tokens.less';

.multiple-inputs {
  width: 100%;
}

.error-list {
  display: block;
  margin: @spacing-md 0;
}

.error-message {
  color: @color-lighthouse-600;
}

@primary-color: #004053;