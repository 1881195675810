@import '../../../../styles/tokens.less';

.right-clipper {
  position: absolute;
  z-index: @z-index-level2;
  top: 0;
  height: 100%;
  background-color: @color-fog-100;
  pointer-events: none;
}

.top-clipper {
  position: absolute;
  z-index: @z-index-level2;
  top: 0;
  left: 0;
  width: 100%;
  background-color: @color-fog-100;
  pointer-events: none;
}

.left-clipper {
  position: absolute;
  z-index: @z-index-level2;
  top: 0;
  left: 0;
  height: 100%;
  background-color: @color-fog-100;
  pointer-events: none;
}

@primary-color: #004053;