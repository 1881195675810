@import '../../../../../../../styles/tokens.less';

.template-list {
  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 100%;
    margin: auto;
  }

  &__no-templates {
    margin: auto;
  }

  &__pills {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

@primary-color: #004053;