@import './tokens.less';

// ----------- Button Dimensions -----------

@button-border-radius: @spacing-xxs;
@button-padding: @spacing-xs @spacing-sm;

@button-font-size: @font-size-sm;
@button-line-height: @spacing-lg;

@button-border-width: 1px solid;
@button-border-offset: -1px;

// ----------- Button Depth -----------

@button-shadow: @shadow-base;
@button-shadow--hover: @shadow-base-hover;
@button-shadow--pressed: @shadow-none;
@button-shadow--selected: @shadow-base-hover;

// ----------- Default/Secondary Button Colours -----------

// Font Color
@button-text-color: @text-color-default;
@button-text-color--hover: @color-orca-000;

// Background Neutral
@button-background: @color-orca-000;

@button-background--hover: @color-lake-100;
@button-background--pressed: @color-ocean-700;

@button-background--hover: @color-lake-100;
@button-background--pressed: @color-ocean-700;

// Background Positive
@button-background-positive: @button-background;

@button-background-positive--hover: @color-seaglass-100;
@button-background-positive--pressed: @color-seaglass-700;

// Background Negative
@button-background-negative: @button-background;

@button-background-negative--hover: @color-lighthouse-100;
@button-background-negative--pressed: @color-lighthouse-700;

// Border Color Neutral
@button-border-color: @color-fog-400;

@button-border-color--hover: @color-lake-700;
@button-border-color--pressed: @color-lake-600;

// Border Color Emphasis
@button-border-color-emphasis: @color-lake-600;

@button-border-color--hover: @color-lake-700;
@button-border-color--pressed: @color-lake-600;

// Border Color Positive
@button-border-color-positive: @color-seaglass-600;

@button-border-color-positive--hover: @color-seaglass-700;
@button-border-color-positive--pressed: @color-seaglass-600;

// Border Color Negative
@button-border-color-negative: @color-lighthouse-500;

@button-border-color-negative--hover: @color-lighthouse-700;
@button-border-color-negative--pressed: @color-lighthouse-600;

// ----------- Primary Button Colours -----------

// Font Color
@button-text-color-primary: @color-orca-000;

@button-border-color-primary: transparent;

// Background Primary
@button-background-primary: @color-lake-700;
@button-background-primary--hover: @color-lake-600;
@button-background-primary--pressed: @color-lake-800;

// Background Positive
@button-background-primary-positive: @color-seaglass-700;
@button-background-primary-positive--hover: @color-seaglass-600;
@button-background-primary-positive--pressed: @color-seaglass-800;

// Background Caution
@button-background-primary-caution: @color-sunset-600;
@button-background-primary-caution--hover: @color-sunset-500;
@button-background-primary-caution--pressed: @color-sunset-700;

//Border Color
@button-border-color-caution: @color-sunset-600;
@button-border-color-caution--hover: @color-sunset-500;

// Background Negative
@button-background-primary-negative: @color-lighthouse-700;
@button-background-primary-negative--hover: @color-lighthouse-600;
@button-background-primary-negative--pressed: @color-lighthouse-800;

// ____Disbaled____
@button-background-disabled: @color-fog-400;

// ----------- Simple Button Styles -----------

// Colors
@simple-icon-button-border: none;
@simple-icon-button-background: transparent;
@simple-icon-button-shadow: none;
@simple-icon-button-color: transparent;
@simple-icon-button-color--hover: @color-ocean-600;

// ----------- Remove Default Animation on Button Click -----------
.ant-btn[ant-click-animating-without-extra-node='true']::after {
  display: none !important;
}
