@import '../../../../styles/tokens.less';

@container-dimension: 60px;
@menu-trigger-content-width: 160px;

.secondary-menu-trigger--title {
  color: @color-light-primary10;
  font-weight: @font-weight-medium;
  line-height: @size-250;
  width: var(--document-style-content-width);
}

.secondary-menu-trigger--desc {
  color: @color-light-neutral-variant50;
  font-size: @font-size-xsm;
  line-height: @size-200;
  letter-spacing: 0.18px;
  width: @menu-trigger-content-width;
}

.secondary-menu-trigger--block {
  display: flex;
  width: 100%;
  height: 68px;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  border-bottom: 1px solid rgba(113, 120, 126, 1);
  cursor: pointer;

  .chevron-container {
    width: @container-dimension;
    height: @container-dimension;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: @spacing-xxs;

    .chevron-icon {
      width: 100%;
      height: 100%;
    }

    &:hover {
      background-color: rgba(25, 28, 30, 0.08);
      border-radius: 50%;
    }
  }
}

@primary-color: #004053;