@import '../../styles/tokens.less';

.py-select {
  --select-color-primary: @color-ocean-500;
  --select-color-primary25: @color-ocean-200;
  --select-color-primary50: @color-ocean-300;

  font-size: @font-size-sm;
  line-height: @line-height-sm;
  font-weight: @font-weight-normal;
  font-style: normal;
  font-family: @font-family;
  color: @color-deepsea-500;
  box-sizing: border-box;
  outline: none;

  & &__control {
    min-height: @size-500;
    border-color: @color-deepsea-400;
    box-shadow: none;

    &:hover {
      border-color: @color-deepsea-500;
      box-shadow: 0px 1px 2px rgba(40, 52, 56, 0.1);
    }

    &.py-select__control--is-focused {
      border-color: @color-ocean-500;
    }
  }

  &__value-container {
    padding-top: calc(@spacing-xxs - 1);
    padding-bottom: calc(@spacing-xxs - 1);
  }

  & &__indicator-separator {
    margin-top: @spacing-sm;
    margin-bottom: @spacing-sm;
    height: @size-200;
  }

  & &__placeholder {
    color: @color-deepsea-500;
  }

  & &__single-value {
    color: @color-deepsea-800;
  }

  & &__option {
    color: @color-deepsea-800;

    &.py-select__option--is-selected {
      color: @color-orca-000;
    }

    &:nth-child(odd) {
      &:not(.py-select__option--is-selected):not(.py-select__option--is-focused) {
        background-color: @color-fog-100;
      }
    }
  }
}

@primary-color: #004053;