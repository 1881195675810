@import '../../../styles/tokens.less';
@import '../../../styles/editor.less';


.section-wrapper {
  width: @editing-area-width;
  min-height: @editing-area-min-height;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
  background-color: white;
  position: relative;
  margin: 0 auto;
}
.button-wrapper{
  width: @editing-area-width;
  height: @size-600;
  margin: 12px auto;
  display:flex;
  justify-content: flex-end;
  align-items: center;
}
@primary-color: #004053;