@import '../../styles/tokens.less';

div.featurelogo-container {
  background-color: @color-orca-000;
  height: @size-800;
  width: @size-800;
  border: 1px solid @color-fog-400;
  border-radius: @radius-sm;
  display: flex;
  justify-content: center;
  align-items: center;

  @media @mobile {
    height: @size-500;
    width: @size-500;
  }
}

.logo {
  height: @size-500;

  @media @mobile {
    height: @size-300;
  }
}

@primary-color: #004053;