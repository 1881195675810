@import '../../../../styles/tokens.less';

.signature__container {
  display: flex;
  flex-direction: column;
  gap: @spacing-lg;
}

.section__label {
  margin-bottom: @spacing-xxs;
}

@primary-color: #004053;