@import '../../../styles/tokens.less';

.plan-page-header-container {
  text-align: center;

  @media @desktop {
    margin-top: @size-800;
    margin-bottom: @size-800;
    .page-info h1.proposify-typography {
      font-size: @font-size-jumbo;
      font-weight: 600;
    }
    .page-info div.proposify-typography {
      font-size: @font-size-jumbo;
      color: @color-fog-600;
      line-height: @font-size-jumbo;
    }
  }

  @media @tablet {
    .page-info h1.proposify-typography {
      font-size: @font-size-xl;
      font-weight: 600;
    }
    .page-info div.proposify-typography {
      font-size: @font-size-xl;
      color: @color-fog-600;
      line-height: @font-size-xl;
    }
  }

  @media @mobile {
    .page-info h1.proposify-typography {
      font-size: @font-size-md;
      font-weight: 600;
    }
    .page-info div.proposify-typography {
      font-size: @font-size-md;
      color: @color-fog-600;
      line-height: @font-size-md;
    }
  }
}

.plan-page-price-card-container {
  @media @mobile {
    .ant-col {
      margin-bottom: @size-300;
    }
    .ant-card.default {
      border: 1px solid @color-fog-400;
      border-radius: @size-100;
    }
    .ant-card.emphasis {
      border-radius: @size-100;
    }
  }
  @media @tablet {
    .ant-col:not(.emphasis-container) {
      padding-top: @size-200;
    }
    .ant-card.default {
      border: 1px solid @color-fog-400;
      border-radius: @size-100 0 0 @size-100;
    }
    .ant-card.emphasis {
      border-radius: @size-100 @size-100 @size-100 0;
    }
  }
  @media @desktop {
    .ant-card.default {
      border-radius: @size-100 0 0 0;
    }
    .ant-col:not(.emphasis-container) {
      padding-top: @size-800;
    }
    .ant-card.emphasis {
      border-radius: @size-100 @size-100 0 0;
    }
  }

  .ant-card {
    height: 100%;
    text-align: center;
    .ant-card-body {
      display: flex;
      flex-direction: column;
      height: inherit;
      flex: 1;
    }

    h1 {
      font-size: @font-size-xxl;
      font-weight: 600;
      text-align: center;
    }
    h2 {
      font-size: @font-size-lg;
      font-weight: 600;
    }
    p {
      font-size: @font-size-sm;
      line-height: @size-300;
      font-weight: 500;
    }
    li {
      border-bottom: none;
      .ant-list-item-meta-description {
        font-size: @font-size-md;
        line-height: @size-300;
        font-weight: 500;
        text-align: left;
      }
    }
    button {
      width: 100%;
      margin-top: auto;
    }
    .py-react-icon {
      > svg {
        width: @size-200;
        height: @size-200;
        vertical-align: text-top;
      }
    }
    .price {
      margin-bottom: @size-500;
    }
    .feature-pretext {
      margin-bottom: 0;
      font-size: @font-size-md;
      line-height: @size-300;
      font-weight: 500;
    }
  }
  .ant-card.default {
    color: @color-fog-800;
    border-top: 1px solid @color-fog-400;
    border-left: 1px solid @color-fog-400;

    .py-react-icon {
      color: @color-deepsea-700;
    }
    li .ant-list-item-meta-description {
      color: @color-fog-800;
    }
    .price p {
      color: @color-fog-600;
    }
    a.see-all-features-link {
      color: @color-fog-800;

      .py-react-icon[title='IcoArrowRight'] > svg {
        color: @color-fog-800;
      }
    }
  }
  .ant-card.emphasis {
    background-color: @color-lake-800;
    color: @color-orca-000;
    h1 {
      color: @color-orca-000;
    }
    h2 {
      color: @color-orca-000;
    }
    li {
      color: @color-orca-000;
      .ant-list-item-meta-description {
        color: @color-orca-000;
      }
    }
    .price p {
      color: @color-fog-400;
    }
    a.see-all-features-link {
      color: @color-orca-000;
    }
  }

  a.see-all-features-link {
    margin-bottom: @spacing-xxl;
    margin-top: @spacing-lg;

    .py-react-icon[title='IcoArrowRight'] > svg {
      width: @size-150;
      height: @size-150;
      margin-top: calc(@size-050 / 2);
      margin-left: @font-size-lg;
    }
  }
}

.feature-table {
  table {
    font-size: @font-size-md;
    line-height: @size-300;
    tr {
      > td {
        border: none;
      }
      > th {
        border-bottom: 1px solid @color-fog-400;
        background: @color-orca-000;
        font-weight: 700;
        color: @color-fog-800;
        padding-top: @size-500;
      }
      > th:first-of-type,
      > td:first-of-type {
        background-color: @color-fog-100;
      }

      > td.default,
      > th.default {
        border-left: 1px solid @color-fog-400;
      }
      > td.emphasis,
      > th.emphasis {
        background-color: @color-lake-800;
      }
      > td:not(:first-of-type) {
        text-align: center;
      }
    }

    tr:hover > td.emphasis {
      background: @color-lake-800;
    }
    tr:hover > td.default {
      background: @color-orca-000;
    }
    tr:hover > td.feature-name {
      background: @color-fog-100;
    }
  }
  .py-react-icon > svg {
    width: @size-200;
    height: @size-200;
    color: @color-deepsea-700;
  }
  .py-react-icon[title='IcoDash'] > svg {
    color: @color-fog-400;
  }
  .emphasis .py-react-icon > svg {
    color: @color-orca-000;
  }

  .ant-table-wrapper:last-of-type tr:last-of-type td:nth-of-type(3),
  .ant-table-wrapper:last-of-type tr:last-of-type td:nth-of-type(2) {
    border-bottom: 1px solid @color-fog-400;
  }
}

.plan-page-faq-container {
  margin-top: @size-800;
}

.page-header-container .tabbed-navigation-wrapper ul {
  background-color: transparent;
  padding-top: @spacing-xxl;
}

.py-react-icon[title='IcoCircleCloseX'] > svg {
  color: @color-lighthouse-700;
}

.feature-table-modal {
  .ant-modal-body {
    padding-top: 0;
  }

  .modal-feature-table {
    table {
      thead tr > th {
        background: transparent;
        font-size: @font-size-md;
        font-weight: bold;
        border-bottom: 1px solid @color-fog-400;
        padding-left: 0;
        padding-top: @spacing-xl;
      }
      tbody tr > td {
        border-bottom: none;
        font-size: @font-size-md;
        padding-left: 0;
        padding-top: @spacing-md;
        padding-bottom: 0;
      }

      .py-react-icon > svg {
        width: @size-200;
        height: @size-200;
        color: @color-deepsea-700;
        vertical-align: text-top;
      }
      .py-react-icon[title='IcoDash'] > svg {
        color: @color-fog-400;
      }
      .emphasis .py-react-icon > svg {
        color: @color-orca-000;
      }
    }

    h2 {
      font-weight: @font-weight-semibold;
      margin-bottom: 0;
    }
  }
}

@primary-color: #004053;