@import '../../../../../styles/tokens.less';

.account {
  &__container {
    background-color: @color-orca-000;
    padding: @size-200 @size-300;
    margin-bottom: @size-100;
    border-radius: @radius-sm;
    box-shadow: @box-shadow-300;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      box-shadow: @box-shadow-500;
    }

    h5 > div {
      display: inline;
      font-weight: 400;
      font-size: 14px;
    }
  }
}

@primary-color: #004053;