@import '../../../../styles/tokens.less';

.new-asset-modal {
  .asset-modal-title {
    color: #191c1e;
    font-family: @default-font;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.12px;
    padding-bottom: 16px;
  }

  .asset-modal-text {
    color: #41484d;
    font-family: @default-font;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.28px;
  }

  .asset-modal-header-close-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-left: 16px;
    color: #41484d;

    &:hover {
      background: rgba(0, 101, 140, 0.08);
    }
  }

  .asset-card {
    display: flex;
    height: 200px;
    padding: 0 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;
  }

  .template-card {
    border-radius: 16px;
    background: #fbfcff;
    border: 1px solid#00658c;
    box-shadow: none;
  }

  .other-assets-card {
    box-shadow: none;
  }

  .asset-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .asset-icon {
    border-radius: 32px;
    border: 2px solid #fff;
  }

  .template-icon {
    background: #4a90e2;
  }

  .other-assets-icon {
    background: #c1c7ce;
  }

  .asset-card-title {
    color: #191c1e;
    font-family: @default-font;
    font-size: 16px;
    font-weight: 600;
    margin-top: 16px;
  }

  .asset-card-text {
    font-family: @default-font;
    color: #41484d;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  .other-assets-chip {
    display: flex;
    margin-top: 6px;
    padding: var(--none, 0px) 6px;
    flex-direction: column;
    align-items: flex-start;
    background: #edeef0;

    & > span {
      color: #001e2d;
      font-family: @default-font;
      font-size: 12px;
      font-weight: 600;
      line-height: 16px; /* 133.333% */
      letter-spacing: 0.18px;
    }
  }

  .asset-modal-button {
    border-radius: 100px;
    font-style: normal;
    text-transform: none;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0.245px;
    font-family: @default-font;
    text-align: center;
    height: 40px;
    padding: 10px 24px;
  }

  .asset-modal-cancel-button {
    color: #00658c;

    &:hover,
    &:focus,
    &:active {
      background: rgba(0, 101, 140, 0.08);
    }
  }

  .asset-modal-create-button {
    background: #00658c;
    color: #fff;

    &:enabled,
    &:focus,
    &:active {
      background: rgb(0, 101, 140, 12%);
      color: #fff;
    }

    &:hover {
      background: #00658c;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    }
  }
}

@primary-color: #004053;