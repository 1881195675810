@import '../../../../styles/tokens.less';

button.ant-btn.primary.py-positive.create-document-button {
  background-color: @color-light-primary20;
  color: @color-light-surface;
  font-size: @font-size-sm;
  font-weight: @font-weight-semibold;
  border-radius: @radius-md;
  padding: @size-150 @size-300;
  width: 173px; //no token
  height: @size-700;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  margin-bottom: @spacing-xl;
  margin-top: @spacing-sm;
  display: flex;
  justify-content: center;
  align-items: center;
}

button.ant-btn.primary.py-positive.create-document-button:hover {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
}

button.ant-btn.primary.py-positive.create-document-button:active {
  background-color: @color-light-primary20;
  outline: none;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
}

.create-document-dropdown {
  .ant-dropdown-menu-title-content {
    padding: 0 @spacing-sm 0 @spacing-sm;
  }

  a {
    text-decoration: none;
    font-weight: @font-weight-normal;
    font-size: @font-size-sm;
    color: @color-navy-10;
  }

  .v3-beta-badge {
    margin-right: 6px;
  }

  li {
    height: @size-500;
  }

  ul.ant-dropdown-menu {
    height: 96px;
    padding: @spacing-xs 0px;
    z-index: @z-index-level2;
  }

  li:nth-of-type(1) {
    padding-left: 54px;
  }

  .ant-dropdown-menu-item {
    padding: 0px 6px;
  }
}

@primary-color: #004053;