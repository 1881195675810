@import '../../styles/pageheader.less';
@import '../../styles/tokens.less';
@import '../../styles/typography.less';

.page-info {
  .action-panel {
    @media @desktop, @tablet {
      display: @page-header-action-panel-display;
      justify-content: @page-header-action-panel-justify-content;
    }

    @media @mobile {
      margin-top: @page-header-action-panel-margin-top--mobile;
    }
  }

  .featurelogo-container {
    @media @desktop, @tablet {
      margin-right: @spacing-lg;
    }

    @media @mobile {
      margin-right: @spacing-md;
    }
  }

  #icon-heading {
    :first-child {
      margin-bottom: 0px;
    }
  }

  h1.proposify-typography {
    @media @desktop, @tablet {
      .heading-1();
    }
    @media @mobile {
      .heading-2();
    }
  }

  div.proposify-typography {
    @media @desktop {
      .paragraph-lg();
    }

    @media @tablet {
      .paragraph-lg();
      margin-bottom: @spacing-md;
    }

    @media @mobile {
      .paragraph-sm();
    }
  }
}

@primary-color: #004053;