@import './tokens.less';

// ----------- Default -----------

.default-typography() {
  color: @text-color-default;
  font-family: @default-font;
}

// ----------- Title Sizes -----------

.heading-1() {
  .default-typography();

  font-size: @font-size-xxl;
  font-weight: @font-weight-normal;
  line-height: @size-500;
  margin-bottom: @spacing-xs;
}

.heading-2() {
  .default-typography();

  font-size: @font-size-xl;
  font-weight: @font-weight-normal;
  line-height: @size-500;
  margin-bottom: @spacing-sm;
}

.heading-3() {
  .default-typography();

  font-size: @font-size-lg;
  font-weight: @font-weight-semibold;
  line-height: @size-300;
  margin-bottom: @spacing-xs;
}

.heading-4() {
  .default-typography();

  font-size: @font-size-xl;
  font-weight: @font-weight-semibold;
  line-height: @size-500;
  margin-bottom: @spacing-xs;
}

.heading-5() {
  .default-typography();

  font-size: @font-size-md;
  font-weight: @font-weight-semibold;
  line-height: @size-300;
  margin-bottom: @spacing-xs;
}

// ----------- Paragraph Classes (Mixins) -----------

.paragraph-xs() {
  .default-typography();

  font-size: @font-size-xsm;
  font-weight: @font-weight-normal;
  line-height: @size-200;
  margin-bottom: @spacing-xs;
}

.paragraph-sm() {
  .default-typography();

  font-size: @font-size-sm;
  font-weight: @font-weight-normal;
  line-height: @size-300;
  margin-bottom: @spacing-lg;
}

.paragraph-md() {
  .default-typography();

  font-size: @font-size-md;
  font-weight: @font-weight-normal;
  line-height: @size-300;
  margin-bottom: @spacing-xs;
}

.paragraph-lg() {
  .default-typography();

  font-size: @font-size-lg;
  font-weight: @font-weight-normal;
  line-height: @size-400;
  margin-bottom: @spacing-xs;
}
