@import '../../../styles/tokens.less';

.document-share-modal {
  --border-radius: @radius-sm;
  .ant-modal-content {
    border-radius: var(--border-radius);
  }
  .ant-modal-header {
    border: none;
    border-radius: var(--border-radius) var(--border-radius) 0 0;
    .ant-modal-title > .ant-typography {
      margin: 0;
    }
  }
  .ant-modal-body {
    padding: @spacing-lg;
    padding-top: @spacing-xs;
  }
}

@primary-color: #004053;