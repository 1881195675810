@import './tokens.less';
@import './typography.less';

// ----------- Typography -----------

.tabbednavigation-menu-label {
  .default-typography();

  line-height: @size-300;

  @media @desktop {
    font-size: @font-size-sm;
    font-weight: @font-weight-semibold;
  }
  @media @mobile {
    font-size: @font-size-lg;
  }
}

.tabbednavigation-submenu-label {
  .default-typography();

  font-size: @font-size-md;
  line-height: @size-300;

  @media @desktop {
    font-weight: @font-weight-semibold;
  }
  @media @mobile {
    font-weight: @font-weight-normal;
  }
}

// ----------- Dimensions -----------

@tabbednavigation-padding: @spacing-md 0;
@tabbednavigation-firstlevel-onlychild-padding: 0 0 @spacing-xs 0;

@tabbednavigation-font-size: @font-size-sm;
@tabbednavigation-line-height: @spacing-lg;

@tabbednavigation-border-width: @size-050;

// ----------- Colours -----------

// Font Color
@tabbednavigation-text-color: @text-color-default;

// Border Color Default
@tabbednavigation-border-color: transparent;
@tabbednavigation-border-color--hover: @color-ocean-500;
@tabbednavigation-border-color--active: @color-ocean-600;

// ----------- List Dimensions -----------

@tabbednavigationlist-border-radius: @spacing-xxs;

@tabbednavigationlist-padding: @spacing-md 0;

@tabbednavigationlist-minwidth: 324px;

@tabbednavigation-list-margin: @spacing-lg;

@tabbednavigation-list-title-to-icon-margin: @spacing-xs;

// ----------- List Colours -----------

// Font Color
@tabbednavigationlist-text-color: @text-color-default;

// Background Color
@tabbednavigationlist-background-color--hover: @color-ocean-100;

// Depth
@tabbednavigationlist-box-shadow: @box-shadow-300;

// ----------- Inline -----------

// Border
@tabbednavigation-inline-border-width: 1px;
@tabbednavigation-inline-border-color: @color-fog-300;
@tabbednavigation-inline-border-radius: @radius-xs;

// Background Color
@tabbednavigation-inline-background-color: @color-orca-000;
