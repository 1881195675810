@import '../../styles/highlight.less';

div.highlight-container {
  margin-bottom: @spacing-md;

  span:first-of-type {
    margin-right: @spacing-md;
  }
}

@primary-color: #004053;