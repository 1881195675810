@import '../../styles/buttons.less';

button.ant-btn {
  padding: @button-padding;
  box-shadow: @button-shadow;
  font-family: @font-family;
  background-color: @button-background;
  border-radius: @button-border-radius;
  border: none;
  outline: @button-border-width @button-border-color;
  outline-offset: @button-border-offset;
  height: unset;
  width: unset;
  @media @mobile {
    width: 100%;
  }

  .btn-font-styles(@font-size-sm,
    @line-height-sm,
    @font-weight-semibold,
    @letter-spacing-reg,
    @button-text-color );

  &:hover,
  &:focus:hover {
    box-shadow: @button-shadow--hover;
    background-color: @button-background--hover;
    outline-color: @button-border-color;
    color: @text-color-default;
  }

  &:active,
  &:visited,
  &:focus:active {
    background-color: @button-background--pressed;
    color: @button-text-color--hover;
    outline-color: @button-border-color--pressed;
  }

  &:focus {
    color: @button-text-color;
    background-color: @button-background;
    outline: @button-border-width @button-border-color;
  }

  &.py- {
    &emphasis {
      outline-color: @button-border-color-emphasis;
    }

    &positive {
      background-color: @button-background-positive;
      outline: @button-border-width @button-border-color-positive;

      &:hover {
        background-color: @button-background-positive--hover;
      }

      &:active,
      &:focus:active,
      &:visited {
        background-color: @button-background-positive--pressed;
        outline: @button-border-width @button-border-color-positive;
      }
    }
    &negative {
      background-color: @button-background-negative;
      color: @button-text-color;
      outline: @button-border-width @button-border-color-negative;

      &:hover {
        background-color: @button-background-negative--hover;
        outline: @button-border-width @button-border-color-negative--hover;
      }

      &:active,
      &:focus:active,
      &:visited {
        background-color: @button-background-negative--pressed;
        color: @button-text-color--hover;
        outline: @button-border-width @button-border-color-negative--pressed;
      }
    }
    svg {
      margin-right: @spacing-xs;
      vertical-align: text-top;
    }
  }
  button.ant-btn-icon-only {
    svg {
      margin: 0 auto;
      display: block;
    }
  }

  &.primary {
    background-color: @button-background-primary;
    outline-color: @button-border-color-primary;
    color: @button-text-color-primary;

    &:hover {
      background-color: @button-background-primary--hover;
      color: @button-text-color-primary;
    }

    &:active,
    &:focus:active {
      background-color: @button-background-primary--pressed;
      outline: @button-border-width @button-background-primary;
      outline-offset: @button-border-offset;
    }

    &.py- {
      &positive {
        background-color: @button-background-primary-positive;
        color: @button-text-color-primary;

        &:hover {
          background-color: @button-background-primary-positive--hover;
          color: @button-text-color-primary;
          outline-color: @button-border-color-primary;
        }

        &:active,
        &:focus:active {
          background-color: @button-background-primary-positive--pressed;
          outline-color: @button-background-primary-positive;
        }
      }
      &caution {
        background-color: @button-background-primary-caution;
        color: @button-text-color-primary;
        outline-color: @button-border-color-caution;

        &:hover {
          background-color: @button-background-primary-caution--hover;
          color: @button-text-color-primary;
          outline-color: @button-border-color-caution--hover;
        }

        &:active,
        &:focus:active {
          background-color: @button-background-primary-caution--pressed;
        }
      }
      &negative {
        background-color: @button-background-primary-negative;
        color: @button-text-color-primary;

        &:hover {
          background-color: @button-background-primary-negative--hover;
          color: @button-text-color-primary;
          outline-color: @button-border-color-primary;
        }

        &:active,
        &:focus:active {
          background-color: @button-background-primary-negative--pressed;
          outline-color: @button-background-primary-negative;
        }
      }
    }
  }

  &.text {
    border: none;
    box-shadow: none;
    outline: none;
    &.py-positive {
      color: @color-seaglass-700;

      &:focus,
      &:active {
        background-color: transparent;
      }
    }
  }

  &[disabled] {
    background-color: @button-background-disabled;
    color: @button-text-color-primary;
    outline-color: @button-border-color-primary;

    &:hover {
      background-color: @button-background-disabled;
      color: @button-text-color-primary;
      outline-color: @button-border-color-primary;
    }
  }
  &.proposify-hamburger-menu,
  &.simple-icon {
    outline: none;

    @media @mobile {
      width: unset;
    }
  }
}

@primary-color: #004053;