@import '../../styles/modal.less';

div.proposify-modal {
  .ant-modal-content {
    display: flex;
    flex-direction: column;

    @media @desktop {
      border-radius: @modal-border-radius;
      min-height: calc(@modal-header-max-height + @modal-footer-max-height + (@modal-content-padding-desktop * 3));
    }
  }

  .ant-modal-header {
    border-top-left-radius: @modal-border-radius;
    border-top-right-radius: @modal-border-radius;

    @media @desktop {
      border-bottom: none;
      padding: @modal-padding-desktop;
      padding-bottom: @modal-content-padding-desktop;
    }

    @media @mobile {
      padding: @modal-padding-mobile;
    }

    @media @tablet {
      padding: @modal-padding-tablet;
    }
  }

  .ant-modal-title {
    font-size: @modal-title-font-size;
    color: @modal-text-color;

    @media @mobile {
      text-align: center;
    }

    img {
      position: relative;
    }

    h3 {
      margin-bottom: 0;
    }
  }

  .ant-modal-body {
    font-size: @modal-description-font-size;
    color: @modal-text-color;

    @media @desktop {
      padding: 0;
      padding-right: @modal-padding-desktop;
      padding-left: @modal-padding-desktop;
    }

    @media @mobile {
      padding: @modal-content-padding-mobile;
      min-height: calc(100vh - @modal-header-max-height - @modal-footer-max-height - (@modal-content-padding-mobile * 2));
    }
  }

  .ant-modal-footer {
    border-top: none;

    @media @desktop {
      padding: @modal-padding-desktop;
    }

    @media @mobile {
      padding: @modal-padding-mobile;
    }

    @media @tablet {
      padding: @modal-padding-tablet;
    }

    button.ant-btn-default {
      border-color: @modal-buttom-cancel-border-color;
    }

    button.ant-btn-primary {
      background-color: @modal-button-confirm;
      border-color: @modal-button-confirm;
      color: @modal-buttom-cancel-color;
    }

    button {
      padding: @modal-buttons-padding;
    }
  }

  .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
    margin-left: @modal-buttons-margin;

    @media @mobile {
      margin: @modal-buttons-margin-mobile 0;
    }
  }

  &.info-modal {
    .ant-modal-footer button.ant-btn-default .default {
      display: none;
    }
  }

  .ant-modal-close-x {
    font-size: @modal-close-button-dimensions;
    color: @modal-text-color;

    @media @desktop {
      position: relative;
      top: @modal-close-button-position-top-desktop;
      right: @modal-close-button-position-right-desktop;
    }

    @media @tablet {
      position: relative;
      top: @modal-close-button-position-top-tablet;
    }
  }
}

@primary-color: #004053;