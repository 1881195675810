@import '../../styles/sectionheader.less';
@import '../../styles/sectiontitle.less';

.proposify-section-header-wrapper {
  @media @desktop {
    .topbar {
      display: flex;
      align-items: center;
      padding-top: @spacing-xs;
      padding-left: 64px;

      .right-side {
        margin-left: auto;
        display: inline-flex;
        align-items: center;
        padding-right: 156px;

        .ant-btn {
          padding: 0;

          &.avatar-button {
            margin-left: @avatar-margin-left;
          }
        }
      }
    }

    .ant-menu-root {
      padding-left: @sectionheader-desktop-padding;
      padding-right: @sectionheader-desktop-padding;
    }

    .tabbed-navigation-wrapper {
      padding-top: @sectionheader-tabbednavigation-padding-top;
    }

    .v3-beta-badge {
      margin-left: 8px;
    }
  }

  @media @tablet, @mobile {
    border-top: @sectionheader-border;
    border-bottom: @sectionheader-border;
    display: flex;
    padding: @sectionheader-topbar-tablet-padding;
    justify-content: left;
    align-items: flex-end;

    .topbar {
      border: none;
      margin-right: @sectionheader-tablet-topbar-margin-right;

      .right-side {
        display: none;
      }
    }

    .tabbed-navigation-wrapper {
      .ant-menu {
        border: none;
      }
    }
  }

  @media @mobile {
    padding: @sectionheader-topbar-mobile-padding;
    align-items: stretch;
    flex-direction: column;

    .tabbed-navigation-wrapper {
      margin: @sectionheader-navigation-margin;

      .proposify-section-title-wrapper {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    .proposify-section-title-wrapper {
      justify-content: left;
    }
  }
}

@primary-color: #004053;