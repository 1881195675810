@import '../../../../styles/tokens.less';

.pipeline-tabs {
  .ant-tabs-nav {
    width: 232px;
    align-items: flex-start;
  }
  .ant-tabs-tab {
    height: @size-400;
    font-size: @font-size-md;
    font-weight: 500;
  }

  .ant-tabs-tab:not(.ant-tabs-tab-active):hover {
    background-color: fade(@color-light-secondary-container, 50%);
    border-radius: 25px;
    color: @color-deepsea-800;
  }

  .ant-tabs-ink-bar .ant-tabs-ink-bar-animated {
    transition: none;
  }

  .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
    padding-left: @spacing-sm;
  }

  .ant-tabs-tab > .ant-tabs-tab-btn {
    display: inline-flex;
  }

  .ant-tabs-tab > .ant-tabs-tab-btn > span {
    padding-right: @spacing-md;
    display: inline-flex;
  }

  .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
    margin: @spacing-xxs 0 0;
  }

  .ant-tabs-tab-active {
    background-color: @color-light-secondary-container;
    border-radius: 25px;
    height: @size-400;
    color: @color-deepsea-800;
    font-weight: @font-weight-semibold;
  }

  .ant-tabs-ink-bar {
    display: none;
  }

  .ant-tabs-left > .ant-tabs-content-holder,
  .ant-tabs-left > div > .ant-tabs-content-holder {
    border-left: none;
  }
}

@primary-color: #004053;