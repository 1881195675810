@import '../../styles/tokens.less';

.app_layout {
  display: flex;
  flex-direction: row;
  min-height: 100%;

  --sidebar-width: @size-700;
}

.app_layout__sidebar_nav {
  width: var(--sidebar-width);
  top: 0;
  bottom: 0;
  position: fixed;
  z-index: @z-index-level5;
}

.app_layout__content {
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  width: 0;
}

.app_layout__sidebar_nav + .app_layout__content {

  padding-left: var(--sidebar-width);
  @media @tablet {
    --sidebar-width: 0;
  }
}

.app_layout__content__header {
  top: 0;
  background-color: @color-light-background;
}

.app_layout__content__main {
  flex: 1 1 0%;
  background-color: @color-light-background;
}

.app_layout__content__main--has_header {
  margin: 0 auto;
  height: 100%;

  @media @desktop {
    padding: @spacing-md 156px @spacing-lg @spacing-sm;
  }

  @media @tablet {
    padding: @spacing-lg @spacing-xxl;
  }

  @media @mobile {
    padding: @spacing-lg @spacing-md;
  }
}

@primary-color: #004053;