@import '../../../styles/tokens.less';

@variables-container-height: 300px;

.variables-container {
  z-index: 999;
  background-color: @color-orca-000;
  color: @color-fog-800;
  padding: @size-150;
  border-radius: @size-050;
  overflow: scroll;
  height: @variables-container-height;
  box-shadow: 0 @size-100 @size-200 rgba(53, 54, 54, 0.1);
  width: 240px;

  span > h3 {
    color: @color-fog-500;
    font-size: @font-size-xsm;
    margin-bottom: 0;
    line-height: @spacing-sm;
    font-weight: @font-weight-semibold;
  }

  ul {
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-direction: column;

    li {
      margin-bottom: 0;
      padding: @size-050;
      cursor: pointer;
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .highlight {
    background-color: @color-deepsea-100;
  }

  .text-variable-value {
    color: @color-deepsea-600;
  }

  .text-variable-vr {
    color: @color-deepsea-300;
    margin-left: @spacing-xxs;
    margin-right: @spacing-xxs;
  }
}

.fr-element {
  .fr-tribute > .text-variable {
    background-color: @color-ocean-300;
  }
}

.py-preview {
  .fr-tribute > .text-variable {
    background-color: transparent;
  }
}

@primary-color: #004053;