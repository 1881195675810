@import '../../../styles/tokens.less';

.block-source {
  width: 115px;
  height: 56px;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: @color-fog-050;
  border: 1px solid @color-fog-200;
  box-sizing: border-box;
  border-radius: @size-050;
  display: inline-flex;
  justify-content: left;
  align-items: center;
  padding: 0;
  transition: all 200ms ease-in-out;

  &:hover {
    cursor: pointer;
    border: 1px solid @color-ocean-300;
    background-color: @color-ocean-100;

    .block-source-icon {
      display: none;
    }

    .block-source-drag-icon {
      display: flex;
      background-color: @color-ocean-300;
      margin-right: @spacing-xs;

      svg {
        width: @size-150;
        height: @size-150;
      }
    }
  }
}

.block-source svg {
  height: 18px;
  width: 18px;
  color: @color-fog-600;
  fill: @color-fog-600;
  vertical-align: middle;
  margin-right: @spacing-xs;
}

.block-source div.proposify-typography.size-sm {
  font-weight: @font-weight-semibold;
  margin-bottom: 0;
}

.block-source.signature-button {
  background-color: @color-sunrise-100;
  border-color: @color-sunrise-400;
}

.block-source.signature-button svg {
  color: @color-sunrise-400;
}

.block-source .block-source-icon,
.block-source .block-source-drag-icon {
  padding-left: @spacing-xs;
  height: 100%;
  display: flex;
  align-items: center;
}

.block-source .block-source-drag-icon {
  display: none;
}

.block-source .block-source-static-icon {
  display: flex;
  padding-left: @spacing-xs;
  height: 100%;
  align-items: center;
}

@primary-color: #004053;