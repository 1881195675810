@import '../../../../styles/tokens.less';

.content-library-page-container {
  .create-asset-button {
    display: flex;
    padding: 16px 20px 16px 16px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    width: 232px;
    height: 56px;
    border-radius: 16px;
    background: #00344a;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
    color: white;
    line-height: 100%;
    text-transform: none;
    font-weight: 600;
    font-family: @default-font;

    &:hover {
      background-color: @color-light-primary20;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
    }

    &:active {
      background-color: @color-light-primary20;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
    }

    & > svg {
      height: 24px;
      width: 24px;
      color: white;
    }
  }
}

@primary-color: #004053;