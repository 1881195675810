@import '../../../../../styles/tokens.less';

.select-template-empty-state {
  .interests-icon {
    height: 32px;
    width: 32px;

    & > svg {
      color: #006877;
      height: 32px;
      width: 32px;
    }
  }

  .select-template-empty-state-title {
    color: #191c1e;
    font-family: @default-font;
    font-size: 28px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0.28px;
  }

  .select-template-empty-state-text {
    color: #41484d;
    text-align: center;
    font-family: @default-font;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.16px;
  }

  .help-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    align-self: center;

    & > svg {
      color: #41484d;
    }
  }

  .go-to-library-button {
    border-radius: 100px;
    font-style: normal;
    text-transform: none;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0.245px;
    font-family: @default-font;
    color: #00658c;
    text-align: center;
    padding: 10px 12px;

    &:hover {
      background: rgba(0, 101, 140, 0.08);
    }
    &:focus,
    &:active {
      background: var rgba(0, 101, 140, 0.12);
    }
  }

  .start-from-scratch-button {
    background-color: #00658c;
    color: @color-light-surface;

    &:hover,
    &:focus,
    &:active {
      background: #00658c;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    }
  }
}

@primary-color: #004053;