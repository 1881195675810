@import '../../../styles/tokens.less';

.proposal-loading {
  display: flex;
  justify-content: center;
  height: 100%;
}

div.proposal-send-page {
  position: fixed;
  inset: 0;
  margin: 0 auto;
  overflow-y: auto;
  background-color: @color-fog-050;
  z-index: 10; // https://github.com/proposify/frontend/pull/428#discussion_r1018214442

  .proposal-send-page__container {
    display: flex;
    flex-direction: column;
    padding: @spacing-xxl;
    max-width: min(100%, 1024px);
    margin: @spacing-xxl auto;
    gap: @spacing-xxl;
    background-color: @color-orca-000;
    box-shadow: @box-shadow-300;
    border-radius: @radius-sm;

    @media @mobile {
      padding: @spacing-md;
    }

    h3 {
      margin-bottom: @spacing-md;
    }

    header {
      display: flex;
      justify-content: space-between;
    }

    .header-section {
      button {
        background: none;
        border: none;
        box-shadow: none;
      }
    }
  }
}

.multiple-inputs {
  width: 100%;
}

@primary-color: #004053;