@import '../../styles/sectiontitle.less';

.proposify-section-title-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: @sectiontitle-icon-size;
    color: @sectiontitle-icon-color;
    margin-right: @sectiontitle-svg-margin;
  }

  .proposify-typography {
    margin: @sectiontitle-margin;
  }
}

@primary-color: #004053;