@import '../../../../styles/tokens.less';

.documents-list-container {
  .MuiTablePagination-root {
    display: flex;
    padding-right: 26px;
    justify-content: flex-end;
    background-color: @color-light-surface;
    border-top-left-radius: 28px;
    border-top-right-radius: 28px;
    height: 48px;
    overflow: hidden;
  }
  .MuiTablePagination-displayedRows {
    margin: 0;
    font-family: @font-family;
    font-size: @font-size-sm;
    font-weight: @font-weight-semibold;
    line-height: 21px;
    letter-spacing: 0.245px;
    color: @color-light-primary40;
  }
}

@primary-color: #004053;