@import '../../styles/tokens.less';
@import '../../styles/avatar.less';

.avatar,
.avatar-image {
  display: flex;
  align-items: center;
  justify-content: center;
  &.color-primary70 {
    background-color: @color-primary70;
  }
  &.color-primary80 {
    background-color: @color-primary80;
  }
  &.color-primary90 {
    background-color: @color-primary90;
  }
  &.color-primary95 {
    background-color: @color-primary95;
  }
  &.color-secondary70 {
    background-color: @color-secondary70;
  }
  &.color-secondary80 {
    background-color: @color-secondary80;
  }
  &.color-secondary90 {
    background-color: @color-secondary90;
  }
  &.color-secondary95 {
    background-color: @color-secondary95;
  }
}

@primary-color: #004053;