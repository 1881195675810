@import '../../../styles/tokens.less';

div.document-create-page {
  position: fixed;
  inset: 0;
  margin: 0 auto;
  overflow-y: auto;
  background-color: @color-fog-050;
  z-index: 10; // https://github.com/proposify/frontend/pull/428#discussion_r1018214442

  .document-create-page{
    &__container {
      display: flex;
      flex-direction: column;
      padding: @spacing-xxl;
      max-width: min(100%, 1024px);
      margin: @spacing-xxl auto;
      background-color: @color-orca-000;
      box-shadow: @box-shadow-300;
      border-radius: @radius-sm;
      min-height: 500px;

      @media @mobile {
        padding: @spacing-md;
      }
    }

    h3 {
      margin-bottom: @spacing-md;
    }

    &__header-section {
        display: flex;
        justify-content: space-between;

      button {
        background: none;
        border: none;
        box-shadow: none;
        cursor: pointer;
      }
    }
  }
}

@primary-color: #004053;