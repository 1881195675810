@import '../../../../styles/tokens.less';


.secondary-menu--section-title {
  color: @color-light-neutral-variant50;
  line-height: @line-height-sm;
  width: 100%;
  font-weight: @font-weight-semibold;
  font-size: @font-size-md;
 
}

.secondary-menu--item-title {
  color: @color-dark-surface;
  font-size: @font-size-sm;
  font-weight: @font-weight-normal;
  line-height: 21px;
  letter-spacing: 0.28px;
  
}

@primary-color: #004053;