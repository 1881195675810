@import '../../styles/colors.less';

.proposify-dropdown-title {
  color: @proposify-cyan;

  &:hover {
    color: @proposify-dark-cyan;
  }
}

.ant-dropdown {
  ul.ant-dropdown-menu {
    padding: 0;
  }
}

@primary-color: #004053;