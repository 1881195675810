@import '../../styles/tokens.less';

.integrations-marketplace-page {
  .cards-container {
    > .ant-col {
      margin-bottom: 24px;
    }
  }

  .page-header-container {
    margin-bottom: 0;
  }
}

.tabbed-navigation-wrapper > ul {
  background-color: @color-light-background;
  border-bottom: none;
}

@primary-color: #004053;