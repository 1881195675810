@import '../../../../styles/tokens.less';
@import '../../../../styles/sidebar.less';

.variables__sidepanel {
  padding-bottom: @spacing-xxl;
  overflow-y: auto;
  margin-right: -15px;
  padding-right: 15px;
  height: @variables-sidepanel-height;

  .ant-form-item {
    margin-bottom: @spacing-md;
  }

  .ant-col-form-item-label {
    padding: 0 0 @spacing-xxs;
  }

  label {
    color: @color-fog-500;
    font-size: @font-size-sm;
  }

  input {
    border-radius: @spacing-xxs;
    background-color: @color-fog-100;
    color: @color-fog-400;
    border: @input-border-colour-disabled;
    padding-bottom: @spacing-xxs;
  }

  input:hover {
    cursor: not-allowed;
    border: @input-border-colour-disabled;
  }
}

.variables__sidepanel {
  &-helper {
    h3.ant-typography.proposify-typography.default-font.desktop {
      color: @color-ocean-500;
      text-align: center;
    }

    h5.ant-typography.proposify-typography.default-font.desktop {
      color: @color-fog-800;
      font-size: @font-size-md;
      margin-bottom: @spacing-md;
    }
    .py-react-icon {
      display: flex;
      justify-content: center;
      padding-bottom: @spacing-md;
    }

    .micro-one {
      font-size: @font-size-xsm;
      margin-bottom: @spacing-md;
    }
  }
}

.variables__sidepanel.ant-form-vertical .ant-col.ant-form-item-label label {
  text-transform: capitalize;
}

@primary-color: #004053;