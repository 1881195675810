@import '../../../styles/tokens.less';
@import '../preview-tokens.less';

.preview__banner {
  background-color: @color-fog-200;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 42px;
  box-shadow: 1px 1px 1px 0 @proposify-gray;
  flex-shrink: 0;
  z-index: @preview-header-z-index;
}

@primary-color: #004053;