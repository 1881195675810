@import '../../../../styles/tokens.less';

.asset-list-container {
  display: flex;
  background-color: white;
  overflow-y: auto;
  flex-grow: 1;
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
  max-height: 85vh;
  margin-left: 24px;

  .content-library-list-header {
    height: 40px;
    color: @light-onsurface-variant;
  }

  .MuiTableHead-root {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.18px;
  }

  .MuiTableCell-root {
    font-family: @default-font;
    font-size: 12px;
    color: @light-onsurface-variant;
    letter-spacing: 0.28px;
    line-height: 100%;
    height: 56px;
    font-weight: 600;
  }

  & svg {
    width: 24px;
    height: 24px;
    color: #004c6a;
  }

  .content-library-name-column {
    width: 75%;
  }

  .content-library-name {
    font-family: @default-font;
    font-size: 14px;
    font-weight: 600;
    color: #191c1e;
  }

  .content-library-last-modified-column {
    width: 20%;
  }

  .content-library-last-modified {
    font-family: @default-font;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
  }

  .content-library-list-row:hover {
    background-color: #1d1b2014;
    cursor: pointer;
  }
}

.asset-list-container::-webkit-scrollbar {
  width: 16px;
}

.asset-list-container::-webkit-scrollbar-thumb {
  background: #71787e;
  border-radius: 100px;
  background-clip: content-box;
  border: 4px solid transparent;
}

.asset-list-container::-webkit-scrollbar-track {
  background: @color-light-surface;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
  margin-top: 50px;
}

@primary-color: #004053;