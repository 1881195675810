@import '../../../styles/tokens.less';
@import '../../../styles/typography.less';

.editor-header {
  position: sticky;
  top: 0;
  z-index: 9;
  padding: 0;
  height: var(--header-height);
  background: @color-orca-000;
  line-height: unset;

  .editor-menu-container {
    padding: @spacing-xxs @spacing-xl;
    display: flex;
    align-items: center;
    flex-flow: row;

    .buttons-container {
      padding-right: @spacing-lg;
      gap: @spacing-md;
      display: flex;
    }

    .editor-menu-container__column--left.document-details {
      input.document-title {
        width: 50vw;
        font-family: @default-font;
        .heading-3();
        transition: all ease-in-out 0.3s;
        border: 1px solid transparent;
        border-radius: @size-050;

        &:hover {
          border: 1px solid @color-ocean-500;
        }
      }

      div.proposify-typography {
        margin-right: @size-400;
      }

      .document-status {
        display: flex;
        align-items: center;

        .timestamp-ml-xs {
          margin-left: @spacing-xs;
        }
      }

      h3.proposify-typography {
        margin-bottom: @size-050;
      }

      div.proposify-typography.size-sm {
        margin-bottom: 0;
      }
    }

    .editor-menu-container__column--right {
      display: flex;
      align-items: center;
    }
  }

  .editor-toolbar-container {
    display: flex;
    justify-content: left;
    align-items: normal;
    width: 100%;
    height: 50px;
    border-top: 1px solid @color-fog-200;
    border-radius: 5px;
    border-bottom: 1px solid @color-fog-200;

    .editor-undo-redo {
      display: flex;
      margin-left: 160px;
      width: auto;
      @media only screen and (max-width: 1331px) {
        margin-left: 3.5vw;
      }

      .svg-icon {
        width: @size-300;
        height: @size-300;
        margin: @size-100 7px @size-100 7px;
      }

      .flip-effect {
        transform: rotate(180deg);
      }
    }

    .editor-froala-toolbar {
      &.hidden {
        display: none;
      }
      &.active {
        display: block;
      }
      .fr-toolbar {
        border: 0;
      }

      .fr-float-left {
        padding-left: @size-250;
      }
    }
  }
}

@primary-color: #004053;