@import '../../styles/sidebar.less';

.app-sidebar-mobile {
  display: flex;
  background-color: @color-orca-000;
  padding: @spacing-md;
  justify-content: space-between;
  align-content: center;
  align-items: center;

  .sidebar-mobile-left,
  .sidebar-mobile-right {
    display: flex;
    align-content: center;
  }
}

.app-sidebar {
  position: @sidebar-position;
  height: @sidebar-height;
  left: @sidebar-left;
  top: @sidebar-top;
  background-color: @sidebar-background-color;
  overflow: @sidebar-overflow;

  .ant-menu {
    background-color: @sidebar-menu-background-color;

    .ant-menu-item-selected {
      background-color: @sidebar-item-background-color--selected;
    }

    .ant-menu-item {
      height: @sidebar-item-height;
      margin: @sidebar-item-margin;
      padding: @sidebar-item-padding;

      &:hover {
        background-color: @sidebar-item-background-color--hover;

        .ant-menu-item-icon {
          color: @sidebar-item-icon-color--hover;
        }
      }

      &::after {
        border: @sidebar-item-border--after;
      }

      .ant-menu-item-icon {
        color: @sidebar-item-icon-color;
        height: @sidebar-item-icon-height;
        width: @sidebar-item-icon-width;
        margin: @sidebar-item-icon-margin;
      }
    }
  }
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: @sidebar-item-background-color--selected;
  }
}

section.ant-layout {
  min-height: @layout-min-height;
}

.ant-tooltip.ant-menu-inline-collapsed-tooltip.ant-tooltip-placement-right {
  padding-left: @sidebar-tooltip-padding-left;

  .ant-tooltip-arrow {
    width: @sidebar-tooltip-arrow-size;
    height: @sidebar-tooltip-arrow-size;

    .ant-tooltip-arrow-content {
      width: @sidebar-tooltip-arrow-size;
      height: @sidebar-tooltip-arrow-size;
      background-color: @sidebar-tooltip-background-color;
      border-radius: @sidebar-tooltip-arrow-border-radius;
    }
  }

  .ant-tooltip-inner {
    background-color: @sidebar-tooltip-background-color;
    font-weight: @font-weight-semibold;
    padding: @sidebar-tooltip-inner-padding;
    border-radius: @sidebar-tooltip-border-radius;
    min-height: 0;
  }
}

@primary-color: #004053;