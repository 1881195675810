@import './tokens.less';
@import './typography.less';
@import './link.less';

a:link {
  color: @link-color;
  display: @link-display-inline;
  font-size: inherit;
  font-weight: @link-font-weight;
  line-height: inherit;
  margin: 0;
  padding: 0;
  text-decoration: @link-text-decoration;
}

a:hover {
  color: @link-color-hover;
  text-decoration: @link-text-decoration;
}

a:active {
  color: @link-color-pressed;
  text-decoration: @link-text-decoration;
}

.ant-spin {
  position: absolute;
  z-index: @loader-z-index;
  left: @loader-left-distance;
  right: @loader-right-distance;
  width: @loader-width;
  top: @loader-top-distance;
}

.loader {
  font-size: @size-600;
}

@primary-color: #004053;