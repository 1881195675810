@import '../../../../../styles/tokens.less';

.configurations {
  &__container {
    margin-top: @spacing-xl;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__table {
    box-shadow: @box-shadow-300;

    .actions {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }

  &__edit_button {
    margin-right: @spacing-md;
  }

  &__add_button {
    float: right;
    margin-bottom: @spacing-lg;
  }

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 100%;
    margin: auto;
  }

  .ant-table {
    min-height: 500px;
  }

  @media @mobile {
    &__edit_button {
      margin-bottom: @spacing-md;
    }

    &__table {
      background-color: red;
      .actions {
        display: flex;
        flex-direction: column;
      }
    }

    &__header {
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

@primary-color: #004053;