@import '../../../styles/tokens.less';
@import '../../../styles/editor.less';
@import '../../../styles/sidebar.less';

.editor-toolbar {
  display: flex;
  flex-direction: column;
  background-color: @color-orca-000;

  .blocks {
    margin-top: @spacing-md;
  }

  .fillable-fields {
    margin-top: @spacing-xxl;

    .block-source-drag-icon {
      display: none;
    }

    .block-source-icon {
      display: flex;
    }
  }

  .block-header div.proposify-typography.size-sm {
    text-transform: uppercase;
    color: @color-fog-500;
    font-weight: @font-weight-normal;
    margin-bottom: 0;
  }
}

.editor-document-builder {
  .fr-wrapper {
    overflow: hidden !important;
  }

  #fr-logo {
    display: none;
  }

  .editor-right-sider {
    position: fixed;
    right: 0;
    top: @editor-header-height;
    height: 100%;
    background-color: transparent;
    z-index: @z-index-level3;
    & > div {
      height: 100%;
    }
    .editor-sidepanel-nav {
      height: 100%;
    }
  }

  .editor-document-main-content {
    padding: @editor-document-main-content-padding-top @editor-sidepanel-width @editor-document-main-content-padding-bottom 0;
  }
}

.user_color {
  --user-border-color: transparent;
  --user-bg-color: @color-orca-000;

  &.sunset {
    --user-border-color: @color-sunset-500;
    --user-bg-color: @color-sunset-200;
  }

  &.sunrise {
    --user-border-color: @color-sunrise-500;
    --user-bg-color: @color-sunrise-200;
  }

  &.ocean {
    --user-border-color: @color-ocean-500;
    --user-bg-color: @color-ocean-200;
  }

  &.seahorse {
    --user-border-color: @color-seahorse-500;
    --user-bg-color: @color-seahorse-200;
  }
  &.urchin {
    --user-border-color: @color-urchin-500;
    --user-bg-color: @color-urchin-200;
  }
  &.deepsea {
    --user-border-color: @color-deepsea-500;
    --user-bg-color: @color-deepsea-200;
  }
  &.seaglass {
    --user-border-color: @color-seaglass-500;
    --user-bg-color: @color-seaglass-200;
  }
}

@primary-color: #004053;