@import '../../styles/colors.less';

.proposify-topbar {
  padding: 0 2em;
  box-shadow: 1px 1px 1px 0 @proposify-gray;

  &-light {
    background-color: @color-orca-000;
  }

  &-dark {
    background-color: @color-orca-999;
  }
}

@primary-color: #004053;