@import '../../../../../../../styles/tokens.less';

.add-user {
  &__add-button.ant-btn.ant-btn-icon-only {
    padding-left: @spacing-sm;
    padding-right: @spacing-sm;
  }

  &__users {
    width: 46%;
  }

  &__roles {
    width: 46%;
  }
}

@media @mobile {
  .add-user {
    &__users {
      padding-bottom: @spacing-xs;
    }

    &__roles {
      padding-bottom: @spacing-xs;
    }
  }
}

@primary-color: #004053;