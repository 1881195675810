@import '../../../../styles/tokens.less';

@document-style-content-width: 160px;

.document_settings_container {
  display: flex;
  flex-direction: column;
}

.document-style-title {
  color: @color-light-primary10;

  font-weight: @font-weight-medium;
  line-height: @size-250;
  width: @document-style-content-width;
}

.document-style-desc {
  color: @color-light-neutral-variant50;

  font-size: @font-size-xsm;
  line-height: @size-200;
  letter-spacing: 0.18px;
  width: @document-style-content-width;
}

.document-style-content {
  display: flex;
  height: 56px;
  flex-direction: column;
  align-items: flex-start;
  gap: @spacing-xxs;
}


@primary-color: #004053;