@import '../../styles/tokens.less';

.py-rich-text-editor {
  width: 100%;
  border: none;
  & > .fr-toolbar,
  & > .fr-second-toolbar,
  &.fr-box.fr-basic > .fr-wrapper {
    border: none;
  }

  .fr-element-scroll-visible {
    height: 120px;
    overflow-y: scroll;
  }

  .fr-btn.fr-dropdown[data-cmd='Email templates'] {
    padding-left: 16px;
    padding-right: 26px;

    &::after {
      right: 12px;
    }
  }
}

@primary-color: #004053;