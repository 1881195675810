@import '../../styles/tokens.less';
@import '../../styles/editor.less';

.editor-header-wrapper {
  position: fixed;
  width: calc(100% - var(--sidebar-width));
  @media @tablet {
    width: 100%;
  } 
  z-index: @z-index-level5;
  right: 0;
  .fr-toolbar {
    border: 1px solid @color-fog-200;
    border-radius: 0;

    .fr-btn-grp {
      margin: 0;
    }

    .fr-btn-grp:nth-child(1) {
      padding-left: @spacing-xxxl;
    }

    .fr-more-toolbar.fr-expanded {
      width: fit-content;
      margin-left: -40px;
    }

    .fr-btn-grp:nth-child(2) {
      padding-left: calc(@spacing-xxxl + @spacing-xxl + @spacing-xs);
    }

    .fr-btn {
      margin: 0;
    }
  }
}

#editing-area {
  padding-right: @editing-area-padding;
  padding-left: @editing-area-padding;

  .fr-box {
    width: @editing-area-width;
    box-shadow: 0px 8px 16px rgba(53, 54, 54, 0.1);

    .fr-wrapper {
      border: none;

      .fr-element {
        min-height: @editing-area-min-height;
      }
    }

    .fr-second-toolbar {
      display: none;
    }
  }
}

#editing-area-details {
  padding-left: 200px;
  padding-bottom: @spacing-xs;

  .proposify-typography {
    margin-bottom: 0;
  }
}

.editor-document-main-content {
  background-color: @color-fog-100;
}

@primary-color: #004053;