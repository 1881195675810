@import '../../../../../styles/tokens.less';

.settings {
  &__container {
    width: 100%;
    background-color: @color-orca-000;
    padding: @spacing-xxl;
    box-shadow: @box-shadow-300;
    border-radius: @radius-sm;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__form {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 60%;
    margin: auto;

    &__item {
      width: 100%;
      margin-bottom: @spacing-lg;
      display: flex;

      &-input {
        width: 100%;
      }

      label {
        min-width: 150px;
        font-weight: @font-weight-semibold;
      }
    }

    &__nested {
      width: 100%;
      margin-bottom: @spacing-lg;
      display: flex;
      flex-direction: column;

      label {
        font-weight: @font-weight-semibold;
      }

      &__form {
        padding: @spacing-md 0 0 @spacing-xxxl;

        &__item {
          width: 100%;
          display: flex;
          align-items: center;
          margin-bottom: @spacing-xs;

          &-input {
            width: 100%;
          }
        }

        label {
          min-width: 75px;
          font-weight: @font-weight-normal;
        }
      }
    }
  }

  &__save_button {
    width: 100%;
    align-self: flex-start;
  }

  @media @mobile {
    &__form {
      flex-direction: column;
      width: 100%;

      &__item {
        width: 100%;
      }
    }
  }
}

@primary-color: #004053;