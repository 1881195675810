@import '../../../../../../styles/tokens.less';

.action-modal__content {
  display: flex;
  flex-direction: column;
  gap: @spacing-lg;
}

.action-modal__text {
  font-size: @font-size-sm;
}
.action-modal__text--remove {
  color: @color-sunrise-600;
  font-weight: 600;
}
.action__subHeading {
  text-transform: uppercase;
}

.action__warning {
  display: flex;
  gap: @spacing-xs;
  margin-top: @spacing-xs;
}

.expire-in {
  display: flex;
  gap: @spacing-xs;
  align-items: center;
}

.input-days__wrapper {
  display: flex;
  gap: @spacing-xxs;
  border-bottom: 1px solid @color-orca-999;
  padding: @spacing-xxs;
  max-width: 96px;
}

.input-days__input {
  border: none;
  width: 100%;
  outline: none;
}

@primary-color: #004053;