@import '../../../../styles/tokens.less';

.create-document-modal {
  .modal-container {
    overflow: hidden;
    margin: 24px;
  }

  .create-document-title {
    color: #191c1e;
    font-family: @default-font;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.12px;
  }

  .create-document-text {
    color: #41484d;
    font-family: @default-font;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.28px;
  }

  .create-document-close-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-left: 16px;
    color: #41484d;

    &:hover {
      background: rgba(0, 101, 140, 0.08);
    }
  }

  .create-document-modal-sort-by-caption {
    color: #41484d;
    font-family: @default-font;
    font-size: small;
    font-weight: 500;
  }

  .create-document-asset-list {
    overflow-y: hidden;
  }

  .asset-list-container {
    margin-left: 0;
    overflow-y: auto;
    max-height: 352px;
  }
}

@primary-color: #004053;