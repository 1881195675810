@import '../../styles/avatar.less';
@import '../../styles/tokens.less';

button.ant-btn.avatar-button {
  width: 44px;
  height: 44px;
  border-radius: @avatar-border-radius;
  overflow: @avatar-overflow;
  padding: @avatar-padding;
  border: @avatar-border;
  box-shadow: @avatar-box-shadow;
  outline: @avatar-border;
  margin-left: @avatar-margin-left;
  margin-right: @avatar-margin-right;

  .avatar {
    width: 44px;
    height: 44px;
    display: flex;
  }
  .avatar-image {
    width: 44px;
    height: 44px;
  }

  &:hover {
    background-color: @avatar-button-background-color;
  }

  &:disabled {
    cursor: @avatar-button-disabled-cursor;
    background-color: @avatar-button-background-color;

    &:hover {
      background-color: @avatar-button-background-color;
    }
  }

  &:focus {
    background-color: @avatar-button-background-color;
    box-shadow: @avatar-box-shadow;
  }

  span.avatar-image {
    width: @avatar-image-width;
    height: @avatar-image-height;
    background-position: @avatar-image-background-position;
    background-size: @avatar-image-background-size;
    background-color: @avatar-background-color;

    span.ant-avatar-string {
      color: @avatar-font-color;
      font-family: @avatar-font-family;

      &:hover {
        color: @avatar-font-color;
      }
    }
  }
}

.ant-dropdown.avatar-dropdown {
  width: @avatar-dropdown-width;
  background-color: @color-orca-000;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

  .ant-dropdown-menu {
    padding: @spacing-md 0;
  }

  a {
    color: initial;
    text-decoration: initial;
    font-weight: @font-weight-medium;
  }

  ul.ant-dropdown-menu li.ant-dropdown-menu-item {
    border: @avatar-dropdown-menu-item-border;
    color: @avatar-dropdown-menu-item-color;
    line-height: @avatar-dropdown-menu-item-line-height;
    font-weight: @avatar-dropdown-menu-item-font-weight;

    &:hover {
      background-color: @avatar-dropdown-menu-item-background-hover;

      a {
        color: initial;
        text-decoration: initial;
      }
    }

    &:active {
      a {
        color: initial;
        text-decoration: initial;
      }
    }
  }

  .features-toggle {
    > p {
      font-weight: @font-weight-medium;
      margin-bottom: @spacing-xs;
      color: @color-orca-999;
    }

    .feature-flag {
      border-radius: @radius-sm;
      font-size: @font-size-xsm;

      &.flag-enabled {
        background-color: #a3eeff;

        &:active,
        &:hover {
          filter: brightness(85%);
        }
      }
    }

    svg {
      width: 18px;
      height: 18px;
    }
  }
}

@primary-color: #004053;