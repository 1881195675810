@import '../../styles/tokens.less';

span.py-react-icon > svg {
  width: @size-300;
  height: @size-300;
  vertical-align: middle;
}

button.ant-btn svg {
  font-size: @font-size-xl;
  margin-right: @spacing-xs;
  display: inline-block;
  vertical-align: middle;
}
button.ant-btn.ant-btn-icon-only {
  padding: @spacing-xs;
  svg {
    margin-right: 0px;
  }
}

.purple {
  color: @color-urchin-500;
}

.coral-red {
  color: @color-seahorse-400;
}

.lighthouse {
  color: @color-lighthouse-700;
}

.sunrise {
  color: @color-sunrise-700;
}

.lake {
  color: @color-lake-700;
}

.seaglass {
  color: @color-seaglass-700;
}

.fog {
  color: @color-fog-800;
}

@primary-color: #004053;