@import '../../../../../styles/tokens.less';

.extra-actions {
  display: flex;
  align-items: center;

  button {
    color: @color-lake-700;
    font-weight: @font-weight-semibold;
    text-decoration: @color-lake-700 underline;
    font-size: @font-size-sm;
    padding: 0 @spacing-xs;
    background: none;
    border: none;
  }
}

@primary-color: #004053;