@import '../../../../styles/tokens.less';
@import '../../../../styles/editor.less';

.block-settings-container,
 .delete-settings-container {
  padding: @spacing-xxs;
  align-items: center;
  position: absolute;
  text-align: center;
  justify-content: center;
  top: 1px - @grid-delete-button-size;
  left: @exact-half;
  width: 52px;
  height: @grid-delete-button-size;
  transform: translateX(-@exact-half);

  border-radius: @spacing-xxs @spacing-xxs 0 0;

  background-color: @color-deepsea-800;
  visibility: hidden;
  display: flex;
}

.block-settings-container:hover,
.delete-settings-container:hover {
  background-color: @color-deepsea-800;
  box-shadow: none;
  outline: none;
  opacity: 1;
  visibility: unset;
}

.delete-settings-container {
  width: 26px;
}
