@import '../../../styles/tokens.less';
@import '../../../styles/typography.less';
@import '../../../styles/editor.less';

.image__library {
  &__box {
    width: 100%;
    height: 50vh;
    overflow-y: scroll;
  }

  &__container {
    margin-top: 24px;
    height: 50%;
  }
}

.image__container_title {
  color: var(--app-fog-500, @color-fog-500);
  font-family: @default-font;
  font-size: @font-size-sm;
  font-style: normal;
  font-weight: @font-weight-normal;
  line-height: @line-height-sm;
}

.image__heading__section {
  border-bottom: 2px solid var(--colour-ocean-500, @color-ocean-500);
  background: @color-fog-050;

  align-items: center;
  display: flex;
  justify-content: center;

  flex-shrink: 0;
  flex-direction: row;

  height: 42px;
  width: 119px;
}

.image__content__section {
  margin-top: 10px;
}

.file-drop-background {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: @z-index-level2;
}

@primary-color: #004053;