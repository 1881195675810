@import '../../styles/tokens.less';

.ant-notification {
  z-index: 9999; //Increased to be visible with MUI Modal
}

.ant-notification-notice {
  box-shadow: @shadow-display-lg;
  padding: @spacing-md @spacing-md 0 @spacing-md;
  border-radius: @size-100;
}

h5.ant-typography,
h5 {
  margin: 0;
}

div.proposify-typography.size-sm {
  margin-bottom: @size-200;
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-bottom: @size-200;
  padding-top: @size-050;
}

.ant-notification-notice-close {
  padding-top: @size-050;
}

@primary-color: #004053;