@import '../../styles/colors.less';
@import '../../styles/fonts.less';
@import '../../styles/spacing.less';
@import '../../styles/sizes.less';

.custom-toast {
  display: flex;
  align-items: center;
  padding: 0;
  padding-left: @spacing-md;
  padding-right: @spacing-xxs;
  padding-top: @spacing-xs;
  padding-bottom: @spacing-xs;
  margin-left: 48px;

  border-radius: 4px;
  background: rgba(0, 26, 33, 0.85);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);

  div.proposify-typography {
    color: @color-ocean-100;
    font-weight: 500;
    letter-spacing: 0.25px;
    margin: 0;
  }

  .toast-close {
    color: @color-ocean-100;
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
  }

  .toast-undo,
  .toast-secondary-action-btn {
    background: transparent;
    border: none;
    color: @color-ocean-300;
    font-weight: @font-weight-semibold;
    cursor: pointer;
    height: @size-500;
    padding-left: @spacing-sm;
    padding-right: @spacing-sm;

    &:hover {
      background: #d0bcff14;
      border-radius: 100px;
    }
  }

  .ant-notification-notice-content {
    flex: 1;
  }

  .ant-notification-notice-message {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
  }

  div[role='alert'] {
    display: flex;
  }

  .ant-notification-notice-btn {
    margin: 0;
  }

  .ant-notification-notice-close {
    display: flex;
    position: static;
    padding: 0;

    &:hover {
      background: #d0bcff14;
      border-radius: 50%;
    }
  }
}

.ant-notification-topLeft .ant-notification-notice,
.ant-notification-bottomLeft .ant-notification-notice.custom-toast {
  margin-left: 48px;
}

@primary-color: #004053;