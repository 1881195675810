@import '../../styles/buttons.less';

.marketplace-listcard {
  &__container {
    padding: @spacing-lg;
    box-shadow: @box-shadow-100;
    border-radius: @radius-sm;
    border: 1px @color-orca-000 solid;
    flex-direction: column;
    width: 100%;
    background-color: @color-orca-000;
    cursor: pointer;

    h4.ant-typography,
    h4 {
      margin: 0;
    }

    div.proposify-typography.size-md {
      margin-bottom: @spacing-md;
    }

    &:hover {
      animation-duration: 0ms;
      border: 1px @color-ocean-500 solid;

      button.ant-btn {
        &.py- {
          &positive {
            background-color: @button-background-positive--hover;
          }
        }

        &.primary {
          &.py- {
            &positive {
              background-color: @button-background-primary-positive--hover;
            }
          }
        }
      }
    }
  }

  &__button {
    padding-left: @spacing-md;
  }

  &__header {
    margin-bottom: @spacing-md;

    &__premium {
      background-color: @color-sunrise-300;
      padding: @spacing-xxs @spacing-xs;
      font-weight: @marketplacelistcard-category-font-weight;
      font-size: @font-size-sm;
      line-height: @size-300;
      text-align: center;
    }

    &__category {
      font-size: @font-size-xsm;
      font-weight: @font-weight-normal;
      line-height: @size-300;
    }
  }

  &--margin-left-auto {
    margin-left: auto;
  }

  &--wrap-reverse {
    flex-wrap: wrap-reverse;
  }
}

@primary-color: #004053;