@import '../../../../styles/tokens.less';

.dragline-container {
  height: 100%;
  overflow: visible;
  position: absolute;
  width: 100%;
  z-index: @z-index-level4;
}

@primary-color: #004053;