@import '../../../../../../../styles/tokens.less';

.linked-accounts-collapse {
  &__container {
    cursor: pointer;
    background-color: @color-orca-000;
    padding: @size-200 @size-300;
    margin-bottom: @size-100;
    border-radius: @radius-sm;
    box-shadow: @box-shadow-300;
  }

  &__table {
    width: 100%;
    margin-top: @size-200;
  }

  &-open {
    height: 100%;
    transform-origin: top;
    transform: scaleY(1);
    transition: transform 0.3s ease-out;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &-close {
    height: 0;
    transform: scaleY(0);
  }
}

@primary-color: #004053;