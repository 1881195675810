@import '../../../styles/tokens.less';
@import '../../../styles/editor.less';
@import '../../../styles/sidebar.less';

div.pdf-page-break {
  position: absolute;
  width: @size-500;
  border-top: 2px dotted;
  font-size: @font-size-xsm;
  padding: 2px;
  user-select: none;
  z-index: @z-index-level2;
}

@primary-color: #004053;