@import '../../../../../../../../styles/tokens.less';

.add-contact-container {
  display: flex;
  gap: @spacing-lg;
  align-items: center;
}

.add-contact__content {
  display: flex;
  align-items: center;
  gap: @spacing-md;
}

.add-contact__avatar {
  width: @size-600;
  height: @size-600;
  background-color: @color-lake-600;
  color: @color-orca-000;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-size: @font-size-lg;
  text-transform: capitalize;
}

@primary-color: #004053;