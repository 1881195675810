@import '../../../../styles/tokens.less';

.content-library-page-container {
  .empty-state-card {
    box-shadow: none;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .empty-state-card-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 18px;
      & > span,
      & > span > svg {
        height: 44px;
        width: 44px;
      }

      .empty-state-title {
        color: var(--material-theme-sys-light-on-surface, #191c1e);

        /* material-theme/headline/medium */
        font-family: @default-font;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px; /* 128.571% */
        letter-spacing: 0.28px;
      }

      .empty-state-text {
        color: var(--material-theme-sys-light-on-surface-variant, #41484d);

        /* material-theme/body/large */
        font-family: @default-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
        letter-spacing: 0.16px;
      }

      .help-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        align-self: center;

        & > svg {
          color: #41484d;
        }
      }
    }

    .create-asset-button {
      background: #00658c;
      width: 137px;
      height: 40px;
      border-radius: 100px;
      gap: 8px;
      padding: 10px 24px 10px 16px;
      color: white;
      text-transform: none;
      box-shadow: none;

      &:hover {
        background: #00658c;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
      }

      &:active {
        background: rgb(0, 101, 140, 12%);
        color: #fff;
      }

      & > svg {
        width: 18px;
        height: 18px;
      }
    }
  }
}

@primary-color: #004053;