@import '../../styles/tokens.less';
@import '../../styles/editor.less';
@import '../../styles/typography.less';
@import './preview-tokens.less';

.preview {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  background-color: @color-fog-100;

  .preview__header,
  .preview__footer {
    flex-shrink: 0;
    flex: 0 0 56px;
    z-index: @preview-header-z-index;
    background-color: @color-orca-000;
  }

  .preview__main {
    flex-grow: 1;
    overflow: auto;
  }

  .preview__footer {
    display: none;
  }

  .preview__header__content {
    max-width: 1280px;
    margin: 0 auto;
  }

  .preview__main__content {
    max-width: @editing-area-width;
    margin: 0 auto;
  }

  .preview__main__grid__content {
    max-width: @pagesize-A4-width;
    margin: 0 auto;
    padding: @spacing-lg 0;
  }

  .preview__header__content,
  .preview__footer__content {
    padding: 0 @spacing-md;
  }

  .preview__main__content {
    padding: @spacing-lg 0;
    overflow: auto;
  }
  .fr-box.fr-basic .fr-wrapper {
    background: transparent;
  }
}

@media @mobile {
  .preview {
    .preview__main__content {
      display: flex;
    }

    .preview__header__content,
    .preview__main__content,
    .preview__footer__content {
      padding: @spacing-md;
    }

    .preview__footer {
      display: block;
    }
  }
}

@primary-color: #004053;