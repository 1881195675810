@path-fonts: 'https://py-production-assets.s3.amazonaws.com/fonts/';
@import './colors.less';
@import './sizes.less';

@default-font: 'proxima-nova';

@font-face {
  font-family: 'proxima-nova';
  src: url('@{path-fonts}ProximaNova-Thin-webfont.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('@{path-fonts}ProximaNova-ThinIt-webfont.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('@{path-fonts}ProximaNova-Thin-webfont.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('@{path-fonts}ProximaNova-ThinIt-webfont.woff') format('woff');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('@{path-fonts}ProximaNova-Thin-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('@{path-fonts}ProximaNova-ThinIt-webfont.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('@{path-fonts}ProximaNova-Light-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('@{path-fonts}ProximaNova-LightIt-webfont.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('@{path-fonts}ProximaNova-Reg-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('@{path-fonts}ProximaNova-RegIt-webfont.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('@{path-fonts}ProximaNova-Sbold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('@{path-fonts}ProximaNova-SboldIt-webfont.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('@{path-fonts}ProximaNova-Bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'proxima-nova';
  src: url('@{path-fonts}ProximaNova-BoldIt-webfont.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'water-brush';
  src: url('@{path-fonts}WaterBrush-Regular.ttf') format('truetype');
  font-style: normal;
}

@font-family: 'proxima-nova';
@font-sans-serif: 'sans-serif';
@font-water-brush: 'water-brush';

@line-height-xs: 16px;
@line-height-sm: 24px;

.btn-font-styles(@size, @line-height, @font-weight, @letter-space, @text-color) {
  font-size: @size;
  line-height: @line-height;
  font-weight: @font-weight;
  letter-spacing: @letter-space;
  color: @text-color;
}

@font-weight-normal: 400;
@font-weight-medium: 500;
@font-weight-semibold: 600;
@font-weight-bold: 800;
@marketplacelistcard-category-font-weight: 700;

@letter-spacing-reg: 0.8px;

@text-color-default: @color-fog-800;
@text-color-light: @color-orca-000;
