@import '../../../../styles/tokens.less';

.signature_dropdown__option__meta {
  .ant-list-item-meta-avatar {
    margin-right: @spacing-sm;
    .ant-avatar {
      background-color: var(--user-bg-color);
      color: @color-deepsea-800;
      font-size: @font-size-xsm;
      font-weight: @font-weight-semibold;
      border: 1px solid var(--user-border-color);
    }
  }
  .ant-list-item-meta-title {
    line-height: 1.1;
    font-weight: @font-weight-semibold;
    color: @color-deepsea-800;
    margin-bottom: 0;
  }
  .ant-list-item-meta-description {
    line-height: 1.2;
    margin-bottom: 0;
    color: @color-deepsea-800;
    word-wrap: break-word;
  }
}

@primary-color: #004053;