.alias {
  display: flex;
  background-color: #ffc;
  border-bottom: 1px solid #ebe9e9;
  padding: 0;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: center;
}

@primary-color: #004053;