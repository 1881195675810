@import '../../../../../../styles/tokens.less';

.email-picker-popover {
  position: relative;

  &__trigger {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    appearance: none;

    &::-moz-focus-inner {
      border: 0;
      padding: 0;
    }
  }

  &__panel {
    background-color: @color-orca-000;
    padding: @spacing-xs @spacing-md;
    box-shadow: @box-shadow-300;
    border-radius: @radius-sm;
    z-index: 1001;

    &--arrow {
      fill: @color-orca-000;
    }
  }
}

span.multi-value-remove > svg {
  width: @font-size-sm;
  height: @font-size-sm;
}

@primary-color: #004053;