@import '../../../styles/tokens.less';

.block-header > div.proposify-typography.size-sm {
  margin-bottom: @spacing-xxs;
}

.editor__toolbar__helper {
  margin-top: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  svg {
    width: 27px;
    height: 27px;
    flex-shrink: 0;

    path:first-child {
      fill: @color-lake-100;
    }

    circle {
      fill: @color-ocean-300;
    }
    path:last-child {
      fill: @color-lake-800;
    }
  }

  &__icon-div {
    text-align: center;
  }

  &__image-title {
    color: var(--app-ocean-500, var(--colour-ocean-500, @color-ocean-500));

    font-family: @default-font;
    font-size: @font-size-lg;
    font-style: normal;
    font-weight: @font-weight-semibold;
    line-height: @line-height-sm;
    text-align: center;
  }

  &__image-helper-desc {
    color: #000;
    width: 238px;

    font-family: @default-font;
    font-size: @font-size-xsm;
    font-style: normal;
    font-weight: @font-weight-normal;
    line-height: @line-height-xs;
  }
}

.blocks {
  &__row {
    margin-bottom: 10px;
  }
}

@primary-color: #004053;