@import '../../../styles/tokens.less';

.signature-modal {
  div.ant-modal-header {
    border-bottom: none;
    padding-bottom: 0;
  }

  .signature-modal-form {
    .form-input-info-wrapper {
      display: flex;
      gap: @spacing-md;

      .form-input-first-name {
        width: 40%;
        order: 1;
      }

      .form-input-last-name {
        width: 40%;
        order: 2;
      }

      .form-input-initials {
        order: 3;
        width: 20%;
      }
    }

    .form-input-email {
      flex-grow: 1;
    }

    .ant-input:focus {
      border: @color-ocean-500;
      box-shadow: 0 0 0 1px @color-ocean-500;
    }
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-right: @spacing-xxs;
    color: @color-seahorse-500;
    font-size: @font-size-sm;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }

  .ant-form-vertical .ant-form-item-label,
  .ant-col-24.ant-form-item-label,
  .ant-col-xl-24.ant-form-item-label {
    padding: 0 0 @size-050;
    line-height: 0;
  }

  .form-signature-paint {
    display: flex;
    flex-wrap: nowrap;
    gap: @spacing-lg;

    .form-signature-paint-fullname,
    .form-signature-paint-initials {
      font-family: @font-water-brush;
      border: 2px solid @color-fog-400;
      min-height: 160px;
      font-size: @font-size-jumbo;
      text-align: center;
      padding: 40px;
    }

    .form-signature-paint-initials {
      width: 30%;
      flex-shrink: 0;
    }

    .form-signature-paint-fullname {
      flex-grow: 1;
      width: 340px;

      span {
        white-space: nowrap;

        &.pre-line {
          white-space: pre-line;
        }
      }
    }
  }
}

.ant-modal-title h3 {
  line-height: @size-500;
  padding-bottom: @spacing-xs;
  font-size: @font-size-xl;
}

@media @mobile {
  .signature-modal.ant-modal.proposify-modal {
    position: absolute;
    top: unset;
    bottom: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: 100%;

    .ant-modal-content {
      border-radius: @radius-sm @radius-sm 0 0;
    }

    .ant-modal-title {
      text-align: left;
      font-size: @font-size-xl;
      line-height: @size-500;
      font-weight: @font-weight-semibold;
    }

    .ant-modal-close-x {
      padding-top: @spacing-xxs;
    }

    .ant-modal-body {
      max-height: calc(100vh - 144px);
      overflow: auto;
      min-height: 200px;
      padding: @spacing-md;
    }

    button.ant-btn {
      width: auto;
    }
    .ant-modal-footer {
      padding: 0;
    }

    .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
      margin: 0px @spacing-md @spacing-md @spacing-md;
    }

    .signature-modal-form {
      .form-input-info-wrapper {
        gap: 0;
        flex-wrap: wrap;

        .form-input-first-name {
          width: 70%;
          order: 1;
          margin-right: @spacing-md;
        }

        .form-input-last-name {
          width: 100%;
          order: 3;
          flex-grow: 1;
        }

        .form-input-initials {
          order: 2;
          flex-grow: 1;
        }
      }

      .ant-form-item-explain-error {
        font-size: @font-size-xsm;
        line-height: @line-height-sm;
      }

      .form-input-email {
        flex-grow: 1;
      }

      .ant-input:focus {
        border: @color-ocean-500;
        box-shadow: 0 0 0 1px @color-ocean-500;
      }
    }

    .form-signature-paint {
      gap: @spacing-xs;
      .form-signature-paint-fullname,
      .form-signature-paint-initials {
        padding: @spacing-md;
      }
    }
  }
}

@primary-color: #004053;