@import '../../../../styles/tokens.less';

.signature_detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: @spacing-md;
}

.signature_detail__content {
  flex-grow: 0;
  font-size: @font-size-sm;
  font-weight: @font-weight-semibold;
  line-height: @size-300;
}

.signature_detail__actions {
  flex-shrink: 0;
  display: flex;
  gap: @spacing-xxs;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}

.signature_detail__action {
  flex-shrink: 0;
  background: none;
  border: none;
  width: @size-300;
  height: @size-300;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@primary-color: #004053;