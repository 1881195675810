@import '../../styles/tokens.less';

.ant-form-vertical .ant-col.ant-form-item-label {
  padding: 0 0 @spacing-xxs;
  .field_label_styles();

  & > label {
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
    color: inherit;
  }
}

@primary-color: #004053;