@import '../../../../../../styles/tokens.less';

.form__field {
  display: flex;
  flex-direction: row;
  background-color: @color-orca-000;
  border: 1px solid @color-fog-400;

  &:not(:only-child) {
    border-bottom-width: 0;

    &:first-child {
      border-radius: @radius-sm @radius-sm 0 0;
    }

    &:last-child {
      border-radius: 0 0 @radius-sm @radius-sm;
      border-bottom-width: 1px;
    }
  }

  &.is-error {
    border-color: @color-lighthouse-600;
    border-bottom-width: 1px;
  }

  &.is-error + &:not(.is-error) {
    border-top-width: 0;
  }

  & .proposify-input {
    border: none;
    height: 40px;

    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }

  &__label {
    flex-shrink: 0;
    padding: @spacing-xs @spacing-md;
    min-width: 88px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__input-container {
    flex-grow: 1;
    flex: 1;
    display: flex;
    padding-right: @spacing-xs;
  }
}

@primary-color: #004053;