@import '../../styles/tokens.less';

div.contentcard-container {
  background-color: @color-fog-050;
  padding: @spacing-lg;
  border: 1px solid @color-fog-400;
  border-radius: @radius-sm;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: @spacing-md;

  div.action {
    margin-left: @spacing-md;
  }

  @media @mobile {
    flex-direction: column;

    div.action {
      margin: 0;
    }
  }
}

@primary-color: #004053;