@import '../../../../styles/colors.less';

.upload-button-container {
  align-items: center;
  background-color: @white;
  border: 1px solid @secondary60;
  border-radius: 1rem;
  cursor: pointer;
  display: flex;
  height: 5rem;
  justify-content: center;
  width: 5rem;

  .upload-button-icon {
    color: @secondary60;
  }

  &:hover {
    background-color: @secondary60-hover;
  }

  &:focus {
    background-color: @secondary60-focus;
  }
}

@primary-color: #004053;