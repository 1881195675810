:root {
  --width-value: 30px;
  --margin-bottom: 30px;
  --margin-left: 10px;
}

.margin-preview {
  position: relative;
  width: 110px;
  height: 140px;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
}

.margin-line {
  position: absolute;
  background-color: rgba(188, 10, 60, 1);
}

.margin-line {
  &.top,
  &.bottom {
    height: 1px;
    width: 100%;
  }

  &.right,
  &.left {
    width: 1px;
    height: 100%;
  }
}

.page-preview {
  position: absolute;
  background-color: white;
}

.page-preview-container {
  position: relative;
  padding-top: 16px;


  .number-input_hide-arrows {

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
  }

  &__top-margin {
    position: absolute;
    top: -20px;
    left: 45%;
    width: var(--width-value);;
  }

  .MuiGrid-container.mui-override,
  &__right-margin.mui-override {
    width: var(--width-value);
    margin-left: var(--margin-left);
    margin-bottom: var(--margin-bottom);
  }

  &__bottom-margin {
    position: relative;
    bottom: 0;
    left: 50%;
    width: var(--width-value);
  }

  .MuiGrid-container.mui-override,
  &__left-margin.mui-override {
    width: var(--width-value);
    margin-right: 10px;
    margin-left: var(--margin-left);
    margin-bottom: var(--margin-bottom);
  }

  .margin-preview-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
}

@primary-color: #004053;