@import '../../../../styles/tokens.less';
@import '../../../../styles/avatar.less';

.pipeline__list {
  border-top-left-radius: 0;
  border-bottom-left-radius: 24px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  overflow-y: auto;
  max-height: calc(100vh - 260px);
  scrollbar-color: @color-neutral80 @color-light-surface;

  .ant-list-header {
    border: none;
  }

  .ant-list-items {
    background-color: @color-orca-000;
    .ant-list-item {
      padding: @spacing-md @spacing-xxl;
      border: 0px solid;
      background-color: @color-orca-000;
      border-left-width: 2px;
      position: relative;
      transition: all 0.1s linear;

      &:not(:first-child) {
        border-top: none;
      }

      &:hover {
        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
        border-left-width: 4px;
        z-index: @z-index-level1;
      }

      &.item-highlight {
        background: #00658c14;
      }

      &.draft {
        border-left-color: #4fb5eb;
      }
      &.sent {
        border-left-color: #13bcd5;
      }
      &.viewed {
        border-left-color: #8660d8;
      }
      &.unsigned {
        border-left-color: #f23d94;
      }
      &.won {
        border-left-color: #18e37d;
      }
      &.lost {
        border-left-color: #f23d5e;
      }
      &.approval {
        border-left-color: #ffbf0a;
      }
      &.approved {
        border-left-color: #a5ced4;
      }
    }
    > a {
      width: 100%;
      text-decoration: none;
    }

    .assigned_user {
      padding: 0 @spacing-md 0 @spacing-md;
      font-family: @font-family;
      color: @color-light-primary10;
      font-size: 11px;
      font-weight: @font-weight-semibold;
      line-height: 17px;
      .avatar,
      .avatar-image {
        width: 40px;
        height: 40px;
        border-radius: @avatar-border-radius;
      }
    }

    .document-title,
    .ant-list-item-meta-description {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .ant-list-item-meta-title {
      font-weight: @font-weight-semibold;
      color: @color-fog-800;
      line-height: @line-height-sm;
      margin: 0;
    }

    .document-title-container {
      display: flex;
      align-items: center;
    }

    .ant-list-item-meta-description {
      font-size: @font-size-xsm;
      font-weight: @font-weight-normal;
      color: @color-fog-800;
      line-height: @line-height-sm;
      margin-top: -6px;
    }
  }

  .document-actions {
    opacity: 0;
    transition: opacity 0.3s;
    position: absolute;
    right: @spacing-xs;
    padding: 0 @spacing-xs 0 @spacing-xs;
    background: @color-orca-000;
    height: 100%;
    display: flex;
    align-items: center;

    .ant-btn {
      outline: none;
      height: fit-content;
      border-radius: 50%;
      padding: @size-100;
      outline: none;
      box-shadow: none;
      background-color: transparent;
      color: @light-onsurface-variant;

      &:hover {
        background-color: fade(@light-onsurface-variant, 8%);
      }

      &:active {
        background-color: fade(@light-onsurface-variant, 12%);
        color: @light-onsurface-variant;
      }
    }
  }

  .ant-list-item:hover .document-actions {
    opacity: 1;
  }

  .pipeline-empty-list {
    color: @color-fog-800;
    max-width: 450px;
    margin-right: auto;
    margin-left: auto;

    span.py-react-icon > svg {
      width: 56px;
      height: 56px;

      path:first-child {
        fill: #d2f5fa;
      }

      path:last-child {
        fill: #00b8d1;
      }
    }
  }

  .v3-beta-badge {
    margin-right: @spacing-xxs;
    width: fit-content;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .document-status-chip {
    font-weight: @font-weight-medium;
    padding-left: 0 @spacing-xs 0 @spacing-xs;
    font-family: @font-family;
    font-size: 11px;

    &.draft {
      background-color: #e1f3ff;
    }
    &.sent {
      background-color: #dff9ff;
    }
    &.viewed {
      background-color: #eaddff;
    }
    &.unsigned {
      background-color: #fcdeec;
    }
    &.won {
      background-color: #cffae4;
    }
    &.lost {
      background-color: #ffdadb;
    }
    &.approval {
      background-color: #f8edd1;
    }
    &.approved {
      background-color: #dfeef0;
    }
  }

  .document-divider {
    margin-right: @spacing-xxs;
    background-color: @light-outline-vairant;
  }

  .document-created-date {
    font-size: 11px;
    font-weight: @font-weight-medium;
    letter-spacing: 0.11px;
  }
}

.pipeline-documents-tooltip {
  font-weight: @font-weight-semibold;
  font-size: @font-size-xsm;
}

.pipeline__list-header > h1 {
  margin-bottom: @size-300;
  font-weight: @font-weight-semibold;
}

.pipeline__list::-webkit-scrollbar {
  width: @size-100;
}

.pipeline__list::-webkit-scrollbar-thumb {
  background: @color-neutral80;
  border-radius: 100px;
}

.pipeline__list::-webkit-scrollbar-track {
  background: @color-light-surface;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
}

@primary-color: #004053;