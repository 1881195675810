@import '../../../styles/spacing.less';
@import '../styles.less';

// Temporary styles to place help button on upper right corner
#help-button {
  float: right;
  margin-top: @spacing-xs;
}

button.simple-icon {
  background-color: @simple-icon-button-color;
  box-shadow: @simple-icon-button-shadow;
  color: @button-text-color;
  border: @simple-icon-button-border;
  height: @size-500;
  width: @size-500;
  padding: @spacing-xs;

  &:active,
  &:focus,
  &:visited,
  &:hover {
    background-color: @simple-icon-button-color;
    box-shadow: @simple-icon-button-shadow;
    color: @simple-icon-button-color--hover;
    border: @simple-icon-button-border;
  }

  &[disabled] {
    background-color: @simple-icon-button-color;
    box-shadow: @simple-icon-button-shadow;
    color: @button-background-disabled;

    &:hover {
      background-color: @simple-icon-button-color;
      box-shadow: @simple-icon-button-shadow;
      color: @button-background-disabled;
      border: @simple-icon-button-border;
    }
  }
}

@primary-color: #004053;