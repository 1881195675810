@import '../../../../../../../styles/tokens.less';

.roles {
  &__container {
    display: flex;
  }

  &__dropdown {
    width: 100%;
    padding-right: @spacing-xs;
  }
}

@primary-color: #004053;