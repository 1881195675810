@import '../../../styles/tokens.less';
@import '../../../styles/sidebar.less';
@import '../../../styles/editor.less';

.editor-sidepanel-nav {
  .ant-tabs-nav {
    padding-top: @spacing-lg;
    min-width: @size-500;
    max-width: @size-500;
    .ant-tabs-tab {
      padding: 0;
      justify-content: center;
      background-color: @color-orca-000;
      box-shadow: 0px 1px 1px rgba(53, 54, 54, 0.2);
    }
    .ant-tabs-tab + .ant-tabs-tab {
      margin: 1px 1px 0 0;
    }
  }

  .editor-sidepanel-nav__icon-wrapper {
    padding: @spacing-xs;
    display: block;
  }

  .ant-tabs-content-holder {
    width: @ant-tabs-content-holder-width;
    flex-shrink: 0;
    background-color: @color-orca-000;
  }
  & .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
    height: 100%;
    padding: @spacing-md @spacing-md @spacing-xxxl @spacing-md;
    position: relative;
    & > div {
      position: relative;
      left: auto;
      right: auto;
    }
  }

  .section__label {
    color: @color-fog-500;
    text-transform: uppercase;
  }

  h5.ant-typography {
    color: @color-fog-800;
    margin-bottom: @spacing-md;
  }

  .ant-form {
    margin-bottom: @spacing-xl;
  }

  .ant-form-item {
    margin-bottom: @spacing-md;

    label {
      text-transform: uppercase;
    }
  }

  .field_label {
    margin-bottom: @spacing-xxs;
    text-transform: uppercase;
  }
}

@primary-color: #004053;