@import './colors.less';
@import './sizes.less';
@import './spacing.less';
@import './typography.less';

// ----------- Simple Form Styles -----------

// label
@label-margin-right: @size-300;

// input
@input-container-border: 1px solid @color-fog-400;
@input-container-background-color: @color-orca-000;

// Select
@select-container-border: 1px solid @color-fog-400;
@select-container-background-color: @color-orca-000;

// Disabled
@input-border-colour-disabled: 1px solid @color-fog-200;

.field_label_styles() {
  font-size: @font-size-sm;
  line-height: @line-height-xs;
  font-weight: @font-weight-normal;
  color: @color-fog-600;
}

.field_label {
  .field_label_styles();
}
