@import '../../styles/tabbednavigation.less';

.tabbed-navigation-wrapper {
  font-family: @font-family;
  background-color: transparent;

  .ant-menu.ant-menu-root.ant-menu-horizontal {
    line-height: @tabbednavigation-line-height;

    .ant-menu-item.ant-menu-item-only-child,
    .ant-menu-submenu.ant-menu-submenu-horizontal,
    .ant-menu-item.has-icon {
      padding: 0;

      &:after {
        border-color: @tabbednavigation-border-color;
        border-width: @tabbednavigation-border-width;
        right: 0;
        left: 0;
      }

      &:hover {
        &:after {
          border-color: @tabbednavigation-border-color--hover;
        }

        svg {
          color: @tabbednavigation-text-color;
        }
      }

      &.ant-menu-item-selected,
      &.ant-menu-submenu-selected {
        &:after {
          border-color: @tabbednavigation-border-color--active;
        }

        svg {
          color: @tabbednavigation-text-color;
        }
      }

      &.ant-menu-submenu-open {
        &:after {
          border-color: @tabbednavigation-border-color--hover;
        }
      }

      & + .ant-menu-item.ant-menu-item-only-child,
      & + .ant-menu-submenu.ant-menu-submenu-horizontal {
        margin-left: @tabbednavigation-list-margin;
      }

      @media @desktop {
        margin-bottom: 1px;
      }
    }

    .ant-menu-item.ant-menu-item-only-child {
      padding: @tabbednavigation-firstlevel-onlychild-padding;

      span.ant-menu-title-content {
        .tabbednavigation-menu-label();

        a {
          color: @tabbednavigation-text-color;
          text-decoration: none;
          float: left;
          margin: 0;
        }
      }

      &:hover {
        .ant-menu-submenu-title {
          span.ant-menu-title-content {
            a {
              color: @tabbednavigation-text-color;
            }
          }
        }
      }
    }

    .ant-menu-item.has-icon {
      padding: @tabbednavigation-firstlevel-onlychild-padding;
      margin-left: @tabbednavigation-list-margin;

      span.ant-menu-title-content {
        .tabbednavigation-menu-label();

        margin-left: @tabbednavigation-list-title-to-icon-margin;

        a {
          color: @tabbednavigation-text-color;
          text-decoration: none;
          font-size: @font-size-sm;
          font-weight: @font-weight-semibold;
        }
      }

      .ant-menu-item-icon svg {
        width: @font-size-sm;
        height: @font-size-sm;
        color: @tabbednavigation-text-color;
      }

      &:first-child {
        margin-left: 0;
      }
    }

    .ant-menu-submenu.ant-menu-submenu-horizontal {
      .ant-menu-submenu-title {
        .tabbednavigation-menu-label();

        svg {
          font-size: @font-size-xl;
        }

        svg + span.ant-menu-title-content {
          float: left;
          margin: 0 @spacing-xs 0 0;
        }

        &:hover {
          color: @tabbednavigation-text-color;
        }
      }

      &.ant-menu-submenu-open {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  .ant-menu.ant-menu-root.ant-menu-inline {
    border: @tabbednavigation-inline-border-width solid @tabbednavigation-inline-border-color;
    border-radius: @tabbednavigation-inline-border-radius;
    overflow: hidden;

    > .ant-menu-item.ant-menu-item-only-child {
      .tabbednavigation-menu-label();
      padding: @spacing-xs @spacing-md !important; // padding-left is inline set by antd
      margin: 0;

      .ant-menu-selected::after,
      .ant-menu-item-selected::after {
        display: none;
      }

      &:hover,
      &.ant-menu-item-selected {
        background-color: transparent;

        a {
          color: @tabbednavigation-text-color;
        }
      }

      &.ant-menu-item-selected::after {
        display: none;
      }

      > .ant-menu-title-content a {
        font-size: @font-size-sm;
        font-weight: @font-weight-semibold;
      }
    }

    .ant-menu-submenu.ant-menu-submenu-inline {
      background: @tabbednavigation-inline-background-color;

      .ant-menu-selected::after,
      .ant-menu-item-selected::after {
        display: none;
      }

      .ant-menu-submenu-title {
        line-height: @tabbednavigation-line-height;
        padding: @spacing-xs @spacing-md !important; // padding-left is inline set by antd
        margin: 0;
        height: auto;
        box-shadow: none;

        > svg {
          font-size: @font-size-xl;
          position: absolute;
          right: @spacing-md;
          color: @tabbednavigation-text-color;
        }

        .ant-menu-title-content {
          margin-left: 0;

          .tabbednavigation-menu-label();
          font-weight: @font-weight-semibold;
        }

        .ant-menu-submenu-arrow {
          display: none;
        }

        &:active {
          background-color: @tabbednavigationlist-background-color--hover;
        }
      }

      .ant-menu.ant-menu-sub.ant-menu-inline {
        background: transparent;

        .ant-menu-item.ant-menu-item-only-child {
          line-height: @tabbednavigation-line-height;
          padding: @spacing-xs @spacing-md !important; // padding-left is inline set by antd
          margin: 0;
          width: 100%;

          .ant-menu-title-content a {
            .tabbednavigation-submenu-label();
          }

          &:hover,
          &.ant-menu-item-selected {
            background-color: @tabbednavigationlist-background-color--hover;

            a {
              color: @tabbednavigation-text-color;
            }
          }
        }

        .ant-menu-submenu.ant-menu-submenu-inline {
          .ant-menu-item.ant-menu-item-only-child,
          .ant-menu-submenu.ant-menu-submenu-inline {
            padding: @spacing-xs @spacing-xl !important; // padding-left is inline set by antd
            margin: 0;
          }

          &:hover {
            .ant-menu-submenu-title {
              background-color: @tabbednavigationlist-background-color--hover;

              .ant-menu-title-content {
                color: @tabbednavigation-text-color;
              }
            }
          }
        }

        .ant-menu-submenu-title .ant-menu-title-content {
          .tabbednavigation-submenu-label();
          font-weight: @font-weight-normal;
        }
      }

      &.ant-menu-submenu-open {
        > .ant-menu-submenu-title > svg {
          transform: rotate(180deg);
        }
      }
    }

    & .has-icon {
      > .ant-menu-title-content a {
        font-size: @font-size-sm;
        font-weight: @font-weight-semibold;
      }

      > .ant-menu-item-icon svg {
        width: @font-size-sm;
        height: @font-size-sm;
        color: @tabbednavigation-text-color;
      }

      &:hover,
      &.ant-menu-item-selected {
        background-color: transparent;

        a {
          color: @tabbednavigation-text-color;
        }
      }

      &.ant-menu-item-selected::after {
        display: none;
      }
    }
  }

  &.has-title-icon {
    position: relative;

    > .ant-menu.ant-menu-root.ant-menu-inline {
      > .ant-menu-submenu.ant-menu-submenu-inline,
      > .ant-menu-item.ant-menu-item-only-child {
        > .ant-menu-submenu-title .ant-menu-title-content,
        > .ant-menu-title-content {
          padding-left: @spacing-lg;
          position: relative;

          .proposify-section-title-wrapper {
            svg {
              position: absolute;
              left: 0;
            }
          }
        }
      }
    }
  }
}

.ant-menu-submenu.ant-menu-submenu-popup.ant-menu {
  min-width: @tabbednavigationlist-minwidth !important; // min-with is inline set by antd
  border-radius: @tabbednavigationlist-border-radius;
  max-height: -webkit-fill-available;

  .ant-menu.ant-menu-sub.ant-menu-vertical {
    box-shadow: @tabbednavigationlist-box-shadow;
    padding: @tabbednavigation-padding;
    max-height: inherit;

    .ant-menu-item.ant-menu-item-only-child {
      line-height: @tabbednavigation-line-height;
      padding: @spacing-xs @spacing-md;
      margin: 0;
      height: auto;

      .tabbednavigation-submenu-label();
    }

    .ant-menu-item.ant-menu-item-only-child {
      &:hover,
      &.ant-menu-item-selected {
        background-color: @tabbednavigationlist-background-color--hover;

        .ant-menu-title-content a {
          color: @tabbednavigation-text-color;
        }
      }
    }

    .ant-menu-submenu.ant-menu-submenu-vertical {
      .ant-menu-submenu-title {
        line-height: @tabbednavigation-line-height;
        padding: @spacing-xs @spacing-xl @spacing-xs @spacing-md;
        margin: 0;
        height: auto;

        > svg {
          display: none;
        }

        .ant-menu-title-content {
          margin: 0;
        }
      }

      .ant-menu-submenu-arrow {
        right: @spacing-md;
        margin-top: -(@spacing-xxs / 2);
      }

      &.ant-menu-submenu-active,
      &.ant-menu-submenu-selected {
        background-color: @tabbednavigationlist-background-color--hover;

        .ant-menu-submenu-arrow {
          color: @tabbednavigation-text-color;
        }
      }
    }
  }
}

.tabbed-navigation-link:link {
  color: initial;
  display: initial;
  font-size: initial;
  font-weight: initial;
  line-height: initial;
  margin: initial;
  padding: initial;
  text-decoration: initial;
}

.tabbed-navigation-link:hover {
  color: initial;
  text-decoration: initial;
}

.tabbed-navigation-link:active {
  color: initial;
  text-decoration: initial;
}

.ant-menu-overflow-item.ant-menu-item.ant-menu-item-only-child {
  a {
    .tabbednavigation-menu-label();
  }
}

@primary-color: #004053;