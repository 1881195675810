@import '../../../../styles/tokens.less';

.pipeline__empty_state_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: @color-light-surface;
  border-radius: 24px;

  .pipeline__empty_state_content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .pipeline__empty_state_content h3 {
    color: @color-orca-999;
    padding-bottom: @spacing-md;
    margin-bottom: 0;
  }

  button.ant-btn.primary.py-positive.all-documents-button {
    border-radius: 100px;
    width: 150px;
    height: @size-500;
    background-color: @color-light-primary40;
    box-shadow: none;

    .py-react-icon {
      display: none;
    }
  }

  .pipeline__empty_state_gif {
    padding-bottom: @spacing-lg;
    max-width: 358px;
    max-height: 180px;
  }

  .proposify-typography.size-md {
    margin-bottom: @spacing-xxs;
    font-size: @font-size-sm;
  }
  .proposify-typography.size-md:nth-child(4) {
    margin-bottom: @spacing-sm;
    line-height: 21px;
  }
}

.pipeline__list .ant-list-empty-text {
  padding: 0;
}

@primary-color: #004053;