@import '../../styles/tokens.less';
@import '../../styles/editor.less';
@import '../../styles/typography.less';

.preview__editor__wrapper__grid {
  width: @editing-area-width;
  padding: 0;
  margin: 0;
  position: relative;
  background-color: white;
  box-shadow: 0px 8px 16px rgba(53, 54, 54, 0.1);
  overflow: hidden;
  z-index: @z-index-level2;

  div[class^="grid_block_"] .fr-wrapper {
    border: none;
  }

  div[class^="grid_block_"] .fr-second-toolbar {
    display: none;
  }

  .fr-box.fr-basic {

    .fr-second-toolbar:empty {
      display: none;
    }

    .fr-wrapper {
      border: none;

      .fr-element {
        padding: 2px;
      }
    }
  }
}

.preview__grid__blocks {
  position: relative;
  z-index: @z-index-base;
}

.preview__grid__signatures {
  width: @editing-area-width;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: @z-index-base;

  .preview__signatures__container {
    width: @editing-area-width;
    padding: 0;
    position: relative;
  }
}

.preview__editor__wrapper__grid .fr-basic .fr-second-toolbar {
  display: none;
}

@media @mobile {
  .preview__footer__document_price {
    font-size: @font-size-sm;
    font-weight: @font-weight-semibold;
    line-height: @size-300;
  }
}

@primary-color: #004053;