@import '../../../../../styles/tokens.less';

.api-clients {
  &__container {
    width: 100%;
    background-color: @color-orca-000;
    padding: @spacing-xxl;
    box-shadow: @box-shadow-300;
    border-radius: @radius-sm;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__form {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 60%;
    margin: auto;

    &__item {
      width: 100%;
      margin-bottom: @spacing-lg;
      display: flex;

      &-input {
        width: 100%;
      }

      label {
        min-width: 150px;
        font-family: @font-family;
        font-weight: @font-weight-semibold;
      }
    }
  }

  &__save_button {
    width: 100%;
    align-self: flex-start;
  }

  @media @mobile {
    &__form {
      flex-direction: column;
      width: 100%;

      &__item {
        width: 100%;
      }
    }
  }
}

@primary-color: #004053;