@import '../../../../../styles/tokens.less';

.opt-out-container {
  text-align: center;
  color: @color-orca-999;
  font-size: @font-size-sm;
  line-height: @line-height-xs;
  margin-bottom: @spacing-xxl;
  font-weight: @font-weight-normal;

  .main-action {
    text-decoration: underline;
    color: @color-lake-500;
    margin-left: @spacing-xxs;
  }
  .admin-text {
    font-weight: @font-weight-bold;
  }
}

@primary-color: #004053;