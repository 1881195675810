@import '../../../../../styles/tokens.less';

.search-workspaces {
  &__container {
    margin-bottom: @size-200;
    display: flex;
    align-items: center;
    width: 100%;
    background-color: @color-orca-000;
    padding: @size-150;
    box-shadow: @box-shadow-300;
    border-radius: @radius-xs;
  }

  &__icon {
    color: @color-fog-400;
  }

  &__input {
    width: 100%;
    border: none;
    margin-left: @size-150;

    &:focus {
      outline: none;
    }
  }
}

@primary-color: #004053;