@import '../../../styles/tokens.less';
@import '../../../styles/typography.less';

.preview_header__column--left h3 {
  margin-bottom: @spacing-xxs;
}

.preview_header__subheading {
  width: 100%;
  height: @size-300;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: @spacing-xl;
}

.preview_header__subheading_block {
  font-family: @default-font;
  font-style: normal;
  font-weight: @size-400;
  font-size: @font-size-sm;
  line-height: @line-height-sm;
  align-items: center;
  color: @color-fog-600;
  flex: none;
  order: 0;
  flex-grow: 0;
}

@media @mobile {
  .preview__header__content {
    .preview_header__column--left h3 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
    }

    .preview_header__subheading {
      display: none;
    }

    .preview_header__column--right {
      display: none;
    }
  }
}

@primary-color: #004053;