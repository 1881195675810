@import '../../../../../styles/tokens.less';

.documents-list-search-bar-area {
  display: flex;
  margin-bottom: @spacing-sm;
  max-width: 660px;
  height: 48px;
  align-items: center;

  .documents-list-search-bar {
    background-color: #def0ff;
    border: none;
    border-radius: 28px;
    transition: background-color 200ms;

    &:not(:has(button:hover)):hover {
      background-color: #def0ff;
      filter: brightness(90%);
    }

    .ant-input-affix-wrapper {
      .ant-input {
        font-size: @font-size-lg;
        color: @color-light-primary10;
        font-weight: @font-weight-normal;
        letter-spacing: 0.16px;

        &::placeholder {
          color: @color-light-primary10;
          font-size: @font-size-lg;
          font-weight: @font-weight-normal;
          letter-spacing: 0.16px;
        }
      }
    }

    .ant-input-group-addon:first-child {
      background-color: transparent;
      border: none;
      padding: 0;
      padding-left: @spacing-xs;
    }

    .ant-input-group-addon:last-child {
      display: none;
    }

    .ant-input-suffix,
    .ant-input-group-addon:first-child {
      button {
        border-radius: 50%;
        color: @color-fog-800;
        background-color: transparent;
        transition: background-color 200ms;
        border: none;
        outline: none;
        box-shadow: none;

        &:hover {
          background-color: #def0ff;
          filter: brightness(90%);
        }

        &:active {
          background-color: #def0ff;
          filter: brightness(90%);
        }
      }
    }
  }

  .ant-input-clear-icon {
    color: @color-fog-800;
  }
}

.documents-list-container {
  .MuiToggleButtonGroup-root.status-filter-bar {
    margin-top: @spacing-xs;
    display: flex;
    height: @size-600;
    justify-content: space-around;
    align-items: center;
    flex-shrink: 0;
    position: fixed;
    bottom: @size-300;
    width: calc(100% - 480px);
  }

  .MuiButtonBase-root.status-button {
    border: none;
    background-color: white;
    display: flex;
    padding: 10px @spacing-sm;
    justify-content: center;
    align-items: center;
    flex: 1 0 0;
    gap: @size-075;
    align-self: stretch;
    color: #001f25;
    font-size: @font-size-sm;
    font-weight: @font-weight-semibold;
    letter-spacing: 0.245px;
    text-transform: capitalize;
    font-family: @font-family;
    &:first-child {
      border-top-left-radius: 28px;
      border-top-right-radius: 0px;
      border-bottom-left-radius: 28px;
      border-bottom-right-radius: 0px;
    }
    &:last-child {
      border-top-left-radius: 0px;
      border-top-right-radius: 28px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 28px;
    }
    &:active,
    &:hover {
      filter: brightness(85%);
    }
    &.MuiButtonBase-root.MuiToggleButton-root.Mui-selected {
      background-color: @color-light-secondary-container;
      &:active,
      &:hover {
        background-color: @color-light-secondary-container;
        filter: brightness(85%);
      }
    }
  }
}

@primary-color: #004053;