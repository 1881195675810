@import '../../../styles/tokens.less';
@import '../../../styles/editor.less';
@import '../../../styles/zindex.less';
@import './BlockSettings/BlockSettingsToolsContainer.less';


.block-border() {
  outline: 1px solid var(--app-ocean-400, #5cd1e5);
  border-radius: 2px;
}
.block-accents() {
  .block-border();
  .editor__page__draggable__grid-drag-handle,
  .editor__page__draggable__grid-resize-handle-icon,
  .block-settings-container,
  .delete-settings-container,
  .editor__page__draggable__grid-delete-handle {
    visibility: visible;
  }
}

.react-draggable-transparent-selection {
  .editor__page__draggable {
    .block-border();
  }

  > .file-drop-background {
    z-index: @z-index-level5;
  }
}

.editor__page {
  &__container {
    z-index: @z-index-level2;
    position: relative;
  }

  .editor__page__draggable {
    position: absolute;
    z-index: @z-index-base;

    &.is_mac {
      cursor: grab !important;

      &:active {
        cursor: grabbing !important;

        .block-accents();
      }

      &:hover {
        .editor__page__draggable__grid-drag-handle {
          cursor: grab;

          &:active {
            cursor: grabbing;
          }
        }
      }
    }

    &.edit-state {
      cursor: text !important;
      .fr-box.fr-basic {
        cursor: text;
      }
    }

    &.selected-state {
      .block-accents();
    }

    &.react-draggable-dragging {
      z-index: @z-index-level5;
      box-shadow: @box-shadow-block;
    }

    &__grid-resize-handle-icon {
      position: absolute;
      visibility: hidden;
      left: 6px;
      top: -3px;

      svg {
        width: @size-075;
        height: @size-075;

        rect {
          fill: white;
          stroke: @color-ocean-400;
        }
      }
    }

    &__grid-drag-handle {
      display: flex;
      visibility: hidden;
      transform: translateX(-100%) translateX(-1px) translateY(-1px);
      position: absolute;
      cursor: text;
      justify-content: center;
      align-items: center;
      width: 16px;
      height: 24px;
      border-radius: 2px;
      background: var(--app-ocean-200, #d2f5fa);
    }

    &:hover,
    &:focus-within {
      .block-accents();

      .editor__page__draggable__grid-drag-handle {
        cursor: move;
      }
    }
  }
  .fr-box.fr-basic .fr-element {
    padding: 2px;
    min-height: @spacing-lg;

    p:only-child {
      margin-bottom: 0;
    }
  }

  .fr-box.fr-basic .fr-wrapper {
    background: transparent;
  }
  &__blocks {
    position: relative;
    z-index: @z-index-base;
  }

  &__signatures {
    position: relative;
    z-index: @z-index-level3;
  }
}

.editor__page [class^='froala_editor_'] {
  .fr-second-toolbar {
    display: none;
  }

  .fr-box {
    .fr-wrapper {
      border: none;
    }
  }
}

@primary-color: #004053;