@import '../../../../../styles/tokens.less';

.collapse {
  &__container {
    margin-top: @size-400;
    width: 100%;
    margin-bottom: 80px;
  }

  &__divider {
    cursor: pointer;
  }

  &__icon-open {
    height: @size-100;
    width: @size-100;
    margin: auto;
    cursor: pointer;
    border-top: 1px @color-fog-600 solid;
    border-right: 1px @color-fog-600 solid;
    transform: rotate(-45deg);
    transition: transform 0.3s ease-in;
  }

  &__icon-close {
    height: @size-100;
    width: @size-100;
    margin: auto;
    cursor: pointer;
    border-top: 1px @color-fog-600 solid;
    border-right: 1px @color-fog-600 solid;
    transform: rotate(135deg);
    transition: transform 0.3s ease-in;
  }

  &-open {
    height: 100%;
    transform-origin: top;
    transform: scaleY(1);
    transition: transform 0.3s ease-out;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: @size-900;
  }

  &-close {
    height: 0;
    transform: scaleY(0);
  }
}

@primary-color: #004053;