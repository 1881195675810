@import '../../styles/tokens.less';

div.video-container {
  width: 100%;
  height: 100%;

  iframe {
    width: 100%;
    height: 100%;
  }
}

@primary-color: #004053;