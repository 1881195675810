@import '../../styles/tokens.less';

.price-input {
  border: none;
  min-width: 120px;

  input {
    transition: all ease-in-out 0.3s;
    border: 1px solid transparent;
    border-radius: @size-050;
    padding-left: 2px;
    margin-left: @size-050;
    line-height: 3px;

    &:hover {
      border: 1px solid @color-ocean-500;
    }
  }

  &.ant-input-number-focused {
    border: none;
    box-shadow: none;
  }

  > .ant-input-number-handler-wrap {
    display: none;
  }
}

@primary-color: #004053;