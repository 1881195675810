@import '../../../styles/tokens.less';

.sso-create {
  &__loader {
    width: 70px;
    margin: auto;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 1;
    top: 40%;
  }
}

.sso-form {
  &__container {
    background-color: @color-fog-050;
    padding: @spacing-xxl;
    box-shadow: @box-shadow-300;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__discovery {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    &__input {
      width: 80%;
    }

    button {
      margin-bottom: 6px;
    }
  }

  &__client {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    &__item {
      width: 100%;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
    }
  }

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 100%;
    margin: auto;
  }

  @media @desktop {
    &__client {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      &__item {
        width: 47%;

        .label {
          text-transform: capitalize;
        }
      }
    }
  }
}


@primary-color: #004053;